/**
 *
 * Partial component, takes in headers
 * and makes a skeleton table that pulses to be a loader
 */
import TableContainer from './TableContainer';
import TableHeader from './TableHeader';
import RefreshButton from '../buttons/RefreshButton';

export default function TableLoader({
  columns,
  title,
  handleRefresh = () => {},
}: {
  columns: string[];
  title: string;
  handleRefresh?: () => void;
}): JSX.Element {
  return (
    <TableContainer>
      <TableHeader title={title}>
        <RefreshButton
          handleRefresh={handleRefresh}
          dataCy="refresh-button-loader"
        />
      </TableHeader>
      <table className="table-auto w-full">
        <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
          <tr>
            {columns.map((col) => {
              return (
                <th className="p-2" key={col}>
                  <div className="font-semibold text-left">{col}</div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-sm font-medium divide-y divide-gray-100 animate-pulse">
          {[1, 2, 3, 4].map((row) => {
            return (
              <tr key={row}>
                {columns.map((col) => {
                  return (
                    <td className="p-2" key={`${row}-${col}`}>
                      <div className="h-2 bg-blue-400 rounded w-3/4"></div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableContainer>
  );
}
