/**
 * Presentational component for the trends graph.
 */

import { useState, useRef, useEffect } from 'react';
import { chartColors } from '../../../charts/ChartjsConfig';
import {
  Chart,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Tooltip,
  Legend,
} from 'chart.js';
import type { ChartData } from 'chart.js';
import 'chartjs-adapter-moment';
import { formatSeconds } from '../../../utils/time';
import type { Average } from '../../../types/Average';
import { useData } from '../../../hooks/useData';
import { tailwindConfig } from '../../../utils/Utils';

Chart.register(
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Tooltip,
  Legend
);

export default function TrendsGraph({
  trendsData,
}: {
  trendsData: Average[];
}): JSX.Element {
  const { selectedDates } = useData();
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const [showTimes, setShowTimes] = useState<boolean>(true);
  const [showVolumes, setShowVolumes] = useState<boolean>(true);

  useEffect(() => {
    setChartData(getChartData(trendsData));
  }, [trendsData]);

  function getChartData(rawData: Average[]): ChartData {
    const labels = getLabels();

    const datasets = getDatasets(rawData);

    return { labels, datasets };
  }

  function getDatasets(rawData: Average[]): any[] {
    const datasets: any[] = [];

    const tailwindColors = tailwindConfig().theme?.colors as Record<
      string,
      any
    >;

    const colors: any[] = [
      tailwindColors.amber[500],
      tailwindColors.blue[500],
      tailwindColors.cyan[500],
      tailwindColors.emerald[500],
      tailwindColors.fuchsia[500],
      tailwindColors.lime[500],
      tailwindColors.orange[500],
      tailwindColors.rose[500],
      tailwindColors.sky[500],
      tailwindColors.violet[500],
      tailwindColors.red[500],
      tailwindColors.green[500],
      tailwindColors.purple[500],
      tailwindColors.pink[500],
      tailwindColors.indigo[500],
      tailwindColors.yellow[500],
      tailwindColors.gray[500],
      tailwindColors.teal[500],
      tailwindColors.zinc[500],
    ];
    // Select a random color from the tailwind config.

    rawData.forEach((location, index) => {
      if (index >= colors.length) {
        index = index - colors.length;
      }

      const color = colors[index];

      const serviceData = {
        label: location.locationName,
        data: location.trendData.map((data) => data.averageServiceTime),
        yAxisID: 'y',
        borderColor: color,
        backgroundColor: color,
      };
      const volumeData = {
        label: location.locationName,
        data: location.trendData.map((data) => data.averageVolume),
        yAxisID: 'y1',
        borderColor: color,
        backgroundColor: color,
        borderDash: [5, 5],
      };
      datasets.push(serviceData, volumeData);
    });

    return datasets;
  }

  const getOrCreateLegendList = (
    chart: Chart,
    id: string
  ): HTMLUListElement | null => {
    const legendContainer = document.getElementById(id);
    if (legendContainer === null) {
      return null;
    }
    let listContainer = legendContainer?.querySelector('ul');

    if (listContainer === undefined) {
      listContainer = document.createElement('ul');
      listContainer.style.display = 'flex';
      listContainer.style.flexDirection = 'row';
      listContainer.style.flexWrap = 'wrap';
      listContainer.style.margin = '0';
      listContainer.style.padding = '0';

      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart: Chart, args: any, options: any) {
      const ul = getOrCreateLegendList(chart, `${options.containerID}`);

      if (ul === null) {
        return;
      }

      // Remove old legend items
      while (ul.firstChild !== null) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const labels = chart.options.plugins?.legend?.labels;
      if (labels?.generateLabels === undefined) return;
      let items = labels.generateLabels(chart);
      // Filter out only items with unique text
      items = items.filter(
        (item: any, index: number, self: any[]) =>
          index === self.findIndex((i: any) => i.text === item.text)
      );

      items.forEach((item: any) => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';

        li.onclick = () => {
          chart.setDatasetVisibility(
            item.datasetIndex as number,
            !chart.isDatasetVisible(item.datasetIndex as number)
          );
          chart.setDatasetVisibility(
            (item.datasetIndex + 1) as number,
            !chart.isDatasetVisible((item.datasetIndex + 1) as number)
          );
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.flexShrink = '0';
        boxSpan.style.height = '20px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '20px';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = '0';
        textContainer.style.padding = '0';
        textContainer.style.textDecoration =
          item.hidden !== undefined && item !== null ? 'line-through' : '';

        const text = document.createTextNode(item.text as string);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };

  function getLabels(): string[] {
    const labels = [];
    // Get all of the days between the first and last date.
    const firstDate = selectedDates[0];
    const lastDate = selectedDates[selectedDates.length - 1];
    const daysBetween = (lastDate.getTime() - firstDate.getTime()) / 86400000;
    for (let i = 0; i <= daysBetween; i++) {
      const date = new Date(firstDate.getTime() + i * 86400000);
      labels.push(date.toISOString().slice(0, 10));
    }
    return labels;
  }

  const [chart, setChart] = useState<Chart | null>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const legend = useRef<HTMLUListElement>(null);
  const darkMode = false;

  const {
    textColor,
    gridColor,
    tooltipBodyColor,
    tooltipBgColor,
    tooltipBorderColor,
  } = chartColors;

  const width = 595;
  const height = 595;

  useEffect(() => {
    const ctx = canvas.current;
    if (ctx === null || chartData === null) return;

    const options: any = {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      indexAxis: 'x',
      layout: {
        padding: {
          top: 12,
          bottom: 16,
          left: 72,
          right: 20,
        },
      },
      plugins: {
        title: {
          display: true,
          text: 'Trends',
        },
        htmlLegend: {
          // ID of the container to put the legend in
          containerID: 'legend-container',
        },
        legend: {
          display: true,
        },
        tooltip: {
          callbacks: {
            title: () => '', // Disable tooltip title
            label: (context: any) => {
              if (context.dataset.yAxisID === 'y1') {
                return `${context.dataset.label}: ${formatSeconds(
                  context.parsed.y as number
                )}`;
              } else {
                return `${context.dataset.label}: ${context.parsed.y}`;
              }
            },
          },
          bodyColor: darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light,
          backgroundColor: darkMode
            ? tooltipBgColor.dark
            : tooltipBgColor.light,
          borderColor: darkMode
            ? tooltipBorderColor.dark
            : tooltipBorderColor.light,
        },
      },
      scales: {
        y: {
          type: 'linear',
          display: true,
          position: 'left',
          border: {
            display: false,
          },
          grid: {
            display: false,
            drawTicks: false,
          },
          ticks: {
            color: darkMode ? textColor.dark : textColor.light,
            callback: (value: number) => formatSeconds(+value),
          },
        },
        y1: {
          type: 'linear',
          display: true,
          position: 'right',
          border: {
            display: false,
          },
          grid: {
            display: false,
            drawTicks: false,
          },
          ticks: {
            color: darkMode ? textColor.dark : textColor.light,
            callback: (value: number) => +value,
          },
        },
      },
      animation: {
        duration: 500,
      },
      maintainAspectRatio: false,
      resizeDelay: 200,
    };

    const newChart = new Chart(ctx, {
      plugins: [htmlLegendPlugin],
      type: 'line',
      data: chartData,
      options,
    });
    setChart(newChart);
    return () => {
      newChart.destroy();
    };
  }, [chartData]);

  useEffect(() => {
    if (chart === null) return;

    if (
      darkMode &&
      chart.options.scales?.x?.ticks !== undefined &&
      chart.options.scales?.x?.grid !== undefined &&
      chart.options.plugins?.tooltip !== undefined
    ) {
      chart.options.scales.x.ticks.color = textColor.dark;
      chart.options.scales.x.grid.color = gridColor.dark;
      chart.options.plugins.tooltip.bodyColor = tooltipBodyColor.dark;
      chart.options.plugins.tooltip.backgroundColor = tooltipBgColor.dark;
      chart.options.plugins.tooltip.borderColor = tooltipBorderColor.dark;
    } else if (
      chart.options.scales?.x?.ticks !== undefined &&
      chart.options.scales?.x?.grid !== undefined &&
      chart.options.plugins?.tooltip !== undefined
    ) {
      chart.options.scales.x.ticks.color = textColor.light;
      chart.options.scales.x.grid.color = gridColor.light;
      chart.options.plugins.tooltip.bodyColor = tooltipBodyColor.light;
      chart.options.plugins.tooltip.backgroundColor = tooltipBgColor.light;
      chart.options.plugins.tooltip.borderColor = tooltipBorderColor.light;
    }
    chart.update('none');
  }, []);

  useEffect(() => {
    toggleData('y');
  }, [showTimes]);

  useEffect(() => {
    toggleData('y1');
  }, [showVolumes]);

  function toggleData(axisId: string): void {
    if (chart === null) return;
    const indices: number[] = [];
    for (let i = 0; i < chart.data.datasets.length; i++) {
      const dataset: any = chart.data.datasets[i];
      const yAxisId = dataset.yAxisID;
      if (yAxisId === axisId) {
        indices.push(i);
      }
    }

    indices.forEach((index) => {
      if (axisId === 'y' && showTimes) {
        chart.show(index);
      }
      if (axisId === 'y1' && showVolumes) {
        chart.show(index);
      }
      if (axisId === 'y' && !showTimes) {
        chart.hide(index);
      }
      if (axisId === 'y1' && !showVolumes) {
        chart.hide(index);
      }
    });
  }

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700 flex justify-between">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          Trend Chart
        </h2>
      </header>
      <div className="flex p-4 gap-4 w-full justify-end">
        <button
          className={`${showTimes ? 'bg-white' : 'bg-gray-100'} p-4`}
          onClick={() => {
            setShowTimes(!showTimes);
          }}
        >
          Times
        </button>
        <button
          className={`${showVolumes ? 'bg-white' : 'bg-gray-100'} p-4`}
          onClick={() => {
            setShowVolumes(!showVolumes);
          }}
        >
          Volumes
        </button>
      </div>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <div id="legend-container" className="grid w-full"></div>
      <div className="px-5 py-4">
        <ul ref={legend} className="flex flex-wrap"></ul>
      </div>
      <div className="grow">
        <canvas
          ref={canvas}
          width={width}
          height={height}
          data-cy="trends-graph"
        ></canvas>
      </div>
    </div>
  );
}
