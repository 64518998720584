export default function TableContainer({
  children,
  dataCy,
}: {
  children: React.ReactNode;
  dataCy?: string;
}): JSX.Element {
  return (
    <div
      className="flex flex-col col-span-full bg-white shadow-lg rounded-lg border border-gray-200 dark:border-slate-700 mb-8"
      data-cy={dataCy}
    >
      {children}
    </div>
  );
}
