/**
 * Bar graph displaying the weekly totals for orders in USD.
 */
import { useState, useRef, useEffect } from 'react';
import { chartColors } from '../../../charts/ChartjsConfig';
import {
  Chart,
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-moment';
import RefreshButton from '../../buttons/RefreshButton';
import type { ChartData } from 'chart.js';

// Import utilities
import { formatValue } from '../../../utils/style';

Chart.register(
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend
);

export default function WeeklyTotalsGraph({
  data,
  handleRefresh,
}: {
  data: ChartData;
  handleRefresh: () => void;
}): JSX.Element {
  const [chart, setChart] = useState<Chart | null>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const legend = useRef<HTMLUListElement>(null);
  const darkMode = false;

  const {
    textColor,
    gridColor,
    tooltipBodyColor,
    tooltipBgColor,
    tooltipBorderColor,
  } = chartColors;

  const width = 595;
  const height = 248;

  useEffect(() => {
    const ctx = canvas.current;
    if (ctx === null) return;

    const newChart = new Chart(ctx, {
      type: 'bar',
      data,
      options: {
        indexAxis: 'x',
        layout: {
          padding: {
            top: 12,
            bottom: 16,
            left: 72,
            right: 20,
          },
        },
        scales: {
          y: {
            border: {
              display: false,
            },
            grid: {
              display: false,
              drawTicks: false,
            },
            ticks: {
              color: darkMode ? textColor.dark : textColor.light,
              callback: (value) => formatValue(+value),
            },
          },
          x: {
            border: {
              display: false,
            },
            ticks: {
              color: darkMode ? textColor.dark : textColor.light,
            },
            grid: {
              color: darkMode ? gridColor.dark : gridColor.light,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => '', // Disable tooltip title
              label: (context) => formatValue(context.parsed.y),
            },
            bodyColor: darkMode
              ? tooltipBodyColor.dark
              : tooltipBodyColor.light,
            backgroundColor: darkMode
              ? tooltipBgColor.dark
              : tooltipBgColor.light,
            borderColor: darkMode
              ? tooltipBorderColor.dark
              : tooltipBorderColor.light,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        animation: {
          duration: 500,
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
    });
    setChart(newChart);
    return () => {
      newChart.destroy();
    };
  }, [data]);

  useEffect(() => {
    if (chart === null) return;

    if (
      darkMode &&
      chart.options.scales?.x?.ticks !== undefined &&
      chart.options.scales?.x?.grid !== undefined &&
      chart.options.plugins?.tooltip !== undefined
    ) {
      chart.options.scales.x.ticks.color = textColor.dark;
      chart.options.scales.x.grid.color = gridColor.dark;
      chart.options.plugins.tooltip.bodyColor = tooltipBodyColor.dark;
      chart.options.plugins.tooltip.backgroundColor = tooltipBgColor.dark;
      chart.options.plugins.tooltip.borderColor = tooltipBorderColor.dark;
    } else if (
      chart.options.scales?.x?.ticks !== undefined &&
      chart.options.scales?.x?.grid !== undefined &&
      chart.options.plugins?.tooltip !== undefined
    ) {
      chart.options.scales.x.ticks.color = textColor.light;
      chart.options.scales.x.grid.color = gridColor.light;
      chart.options.plugins.tooltip.bodyColor = tooltipBodyColor.light;
      chart.options.plugins.tooltip.backgroundColor = tooltipBgColor.light;
      chart.options.plugins.tooltip.borderColor = tooltipBorderColor.light;
    }
    chart.update('none');
  }, []);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700 flex justify-between">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          This Week
        </h2>
        <RefreshButton
          handleRefresh={handleRefresh}
          dataCy="refresh-button-weekly-totals-graph"
        />
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <div className="px-5 py-4">
        <ul ref={legend} className="flex flex-wrap"></ul>
      </div>
      <div className="grow">
        <canvas
          ref={canvas}
          width={width}
          height={height}
          data-cy="weekly-totals-graph"
        ></canvas>
      </div>
    </div>
  );
}
