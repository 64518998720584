import { useEffect, useState } from 'react';
import AccordionBasic from '../../../../../components/accordions/AccordionBasic';
import DropdownFilter from '../../../../../components/dropdowns/DropdownFilter';
import { useData } from '../../../../../hooks/useData';
import { FranchiseConfiguration } from '../../../../../types/FranchiseConfiguration';
import StandardButton from '../../../../../components/buttons/StandardButton';
import { updateFranchiseConfiguration } from '../../../../../utils/api';

const EmailConfiguration: React.FC = () => {
    const [editFranchiseConfiguration, setEditFranchiseConfiguration] = useState<FranchiseConfiguration | null>();
    const { 
        franchiseConfiguration,
        setFranchiseConfiguration,
        franchiseUsers,
        selectedFranchise
    } = useData();

    useEffect(() => {
        setEditFranchiseConfiguration(franchiseConfiguration);
    }, [franchiseConfiguration]);

    const handleEmailChange = (id: string) => {
        if (!editFranchiseConfiguration) return;

        const user = franchiseUsers.find(u => u.userId === id);
        if (!user) return;

        let newMailingList;
        if (editFranchiseConfiguration.emailConfiguration.mailingList.includes(user.email)) {
            // Remove the email if it already exists
            newMailingList = editFranchiseConfiguration.emailConfiguration.mailingList.filter(email => email !== user.email);
        } else {
            // Add the email if it does not exist
            newMailingList = [...editFranchiseConfiguration.emailConfiguration.mailingList, user.email];
        }

        // Update the state with the new mailing list
        setEditFranchiseConfiguration({
            ...editFranchiseConfiguration,
            emailConfiguration: {
                ...editFranchiseConfiguration.emailConfiguration,
                mailingList: newMailingList
            }
        });
    };

    const handleSave = () => {
        if (!editFranchiseConfiguration) return;

        updateFranchiseConfiguration(selectedFranchise!.id, editFranchiseConfiguration)
            .then(() => {
                // Update global state with the new mailing list
                setFranchiseConfiguration({
                    ...editFranchiseConfiguration,
                    emailConfiguration: {
                        ...editFranchiseConfiguration.emailConfiguration,
                        mailingList: editFranchiseConfiguration.emailConfiguration.mailingList
                    }
                });
            })
            .catch((error) => {
                console.error(
                `Error updating Franchise configuration for ${selectedFranchise?.id}:`,
                error
                );
            });
    };

    if (!editFranchiseConfiguration) return <div>Loading...</div>;

    return (
        <AccordionBasic title={"Email Reports"} dataCy="franchise-email-accordion">
            <div className="flex flex-wrap w-full gap-4">
                {/* Change Email */}
                <div className="flex flex-col gap-4">
                    <label>Users to get email reports</label>
                    <DropdownFilter
                        title={editFranchiseConfiguration.emailConfiguration.mailingList.length + " users"}
                        options={franchiseUsers.map((u) => {return {id: u.userId, value: u.email}})}
                        selected={editFranchiseConfiguration.emailConfiguration.mailingList.map((u) => {return {id: franchiseUsers.find((user) => user.email === u)!.userId, value: u}})}
                        handleChange={handleEmailChange}
                        dataCy={'dropdown-franchise-email-multiple'}
                    />
                    <div className="flex flex-wrap gap-8 w-full">
                    <StandardButton onClick={handleSave} dataCy="update-mailing-list">
                        Save Updates
                    </StandardButton>
                    </div>
                </div>
            </div>
        </AccordionBasic>
    );
};

export default EmailConfiguration;
