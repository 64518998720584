export default function FilterModal({
    filterOptions,
    selectedFilters, 
    setSelectedFilters, 
    onApply 
}: {
    filterOptions: Record<string, string[]>;
    selectedFilters: Record<string, string[]>;
    setSelectedFilters: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
    onApply: () => void;
}): JSX.Element {
    const handleCheckboxChange = (column: string, option: string) => {
      setSelectedFilters((prev) => {
        const currentSelections = prev[column] || [];
        if (currentSelections.includes(option)) {
          return { ...prev, [column]: currentSelections.filter(opt => opt !== option) };
        } else {
          return { ...prev, [column]: [...currentSelections, option] };
        }
      });
    };
  
    return (
      <div className="flex flex-col space-y-3 my-2" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
        {Object.keys(filterOptions).map(column => (
          <div className="mx-8" key={column}>
            <h4>{column}</h4>
            {filterOptions[column].map(option => (
              <div key={option} className="flex items-center">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={selectedFilters[column]?.includes(option) || false}
                    onChange={() => handleCheckboxChange(column, option)}
                  />
                  <div className="w-4 h-4 rounded-md border-2 border-slate-400 peer-checked:bg-blue-400 peer-checked:border-blue-400 transition-all"></div>
                  <span className="ml-2">{option}</span>
                </label>
              </div>
            ))}
          </div>
        ))}
        <button       
          className="button bg-transparent hover:bg-blue-400 text-blue-400 hover:text-white rounded-full border border-blue-400 py-1 px-2 m-9 text-xs min-w-20 self-center"
          style={{ maxHeight: '2.5em' }} 
          onClick={onApply}
        >
          Apply
        </button>
      </div>
      );
    };