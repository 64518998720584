/**
 * Presentational component to toggle between drive-thru and lobby.
 */
import { DriveEta, DirectionsWalk } from '@mui/icons-material';
import { useData } from '../../hooks/useData';

export default function DriveThruLobbyToggle(): JSX.Element {
  const { toggled, handleToggle } = useData();
  const toggles = [
    {
      icon: DirectionsWalk,
      alt: 'Lobby',
      name: 'lobby',
    },
    {
      icon: DriveEta,
      alt: 'Drive-thru',
      name: 'drive-thru',
    },
  ];

  return (
    <div className="flex justify-center gap-4">
      {toggles.map((toggle) => (
        <button
          key={toggle.name}
          onClick={() => {
            handleToggle(toggle.name as 'lobby' | 'drive-thru');
          }}
          className={`btn p-1 ${
            toggled === toggle.name
              ? 'bg-white shadow-sm dark:bg-slate-700'
              : 'bg-gray-100 dark:bg-slate-900'
          } focus:outline-none`}
          data-cy={`${toggle.name}-toggle`}
        >
          <toggle.icon />
        </button>
      ))}
    </div>
  );
}
