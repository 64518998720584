import Flatpickr from 'react-flatpickr';
import type { Options, Hook } from 'flatpickr/dist/types/options';

export default function TimeSelect({
  onChange,
  value,
  dataCy = 'time-select',
}: {
  onChange: (dateStr: string) => void;
  value?: string;
  dataCy?: string;
}): JSX.Element {
  const options: Options = {
    noCalendar: true,
    enableTime: true,
    dateFormat: 'H:i',
  };

  const handleChange: Hook = (selectedDates, dateStr, instance) => {
    console.log(instance);
    onChange(dateStr);
  };

  return (
    <Flatpickr
      className="form-input dark:bg-slate-800 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200 font-medium"
      options={options}
      onChange={handleChange}
      value={value}
      data-cy={dataCy}
    />
  );
}
