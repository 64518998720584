/**
 * Custom-styled table component.
 */

export default function Table({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div className="overflow-hidden rounded-lg">
      <div className="overflow-x-auto max-h-96 overflow-y-auto">
        <table className="table-fixed w-full divide-y min-w-98">
          {children}
        </table>
      </div>
    </div>
  );
}
