import { useState } from "react";
import DropdownFilter from "../../dropdowns/DropdownFilter";
import ModalBasic from "../../modals/ModalBasic";
import { FranchiseUser } from "../../../types/FranchiseUser";
import { Roles } from "../../../types/Roles";
import { FranchiseLocation } from "../../../types/FranchiseLocation";

export default function NewUserItem({
    franchiseLocations,
    franchiseId,
    isOpen,
    setIsOpen,
    onSave
  }: {
    franchiseLocations: Array<FranchiseLocation>;
    franchiseId: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    onSave: (user: FranchiseUser) => void;
  }): JSX.Element {
    const emptyUser = {userId: "", userRole: Roles.MEMBER, email: "", franchiseId: franchiseId, locationIds: []};
    const userRoles = [{id: Roles.ADMIN, value: Roles.ADMIN}, {id: Roles.MANAGER, value: Roles.MANAGER}, {id: Roles.MEMBER, value: Roles.MEMBER}];
    const [newUser, setNewUser] = useState<FranchiseUser>(emptyUser);

    const handleSave = () => {
        onSave(newUser);
        setIsOpen(false);
    };

    const handleDiscardChanges = () => {
        setNewUser(emptyUser);
    }
    
    function handleSelectedLocationsChange(id: string): void {
        let newSelectedLocations;

        const locationSelected = newUser.locationIds.find((locId) => locId === id);

        if (locationSelected !== undefined) {
            newSelectedLocations = newUser.locationIds.filter((locId) => locId !== id);
            setNewUser({...newUser, locationIds: newSelectedLocations});
        } else {
            const location = franchiseLocations.find((loc) => loc.locationId === id);
            newSelectedLocations = [...newUser.locationIds, location?.locationId!];
            setNewUser({...newUser, locationIds: newSelectedLocations});
        }
    }

    const emailIsValid = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    const isAddUserButtonEnabled = emailIsValid(newUser.email) && newUser.locationIds.length > 0;

    return (
    <ModalBasic
      title="Add New User"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dataCy="modal-download-video"
    >
        <div className="flex flex-col gap-4 min-w-96 p-4">
            {/* Change Email */}
            <div className="flex flex-col">
                <label>Email address</label>
                <input
                    className="form-input"
                    placeholder="smith@yourcompany.com"
                    onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                    name={`${newUser.email}-name`}
                    data-cy="day-part-name-input"
                    value={newUser.email}
                />
            </div>
            {/* Change Role */}
            <div className="flex flex-col">
                <label>Role</label>
                <DropdownFilter
                    title={newUser.userRole}
                    options={userRoles}
                    selected={[{id: newUser.userRole, value: newUser.userRole}]}
                    handleChange={(id) => setNewUser({ ...newUser, userRole: userRoles.find((val) => val.id == id)?.value!})}
                    dataCy={'dropdown-region-multiple'}
                />
            </div>
            {/* Change Locations */}
            <div className="flex flex-col">
                <label>Locations</label>
                <DropdownFilter
                    title={`${franchiseLocations.filter((loc) => newUser.locationIds.includes(loc.locationId)).length} Locations`}
                    options={franchiseLocations.map((loc) => { return {id: loc.locationId, value: loc.displayName}})}
                    selected={franchiseLocations.filter((loc) => newUser.locationIds.includes(loc.locationId)).map((loc) => { return {id: loc.locationId, value: loc.displayName}})}
                    handleChange={handleSelectedLocationsChange}
                    dataCy={'dropdown-region-multiple'}
                />
            </div>
            {/* Save Button */}
            <div className="flex flex-col">
                <button
                    className={`btn ${isAddUserButtonEnabled ? 'border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm text-indigo-500' : 'text-gray-500 border-gray-200 cursor-not-allowed'}`}
                    onClick={handleSave}
                    disabled={!isAddUserButtonEnabled}
                    data-cy="save-user-button"
                >
                    Add User
                </button>
            </div>
            {/* Save Button */}
            <div className="flex justify-end">
                <button
                    className="btn bg-orange-500 hover:bg-orange-600 text-white"
                    onClick={handleDiscardChanges}
                    data-cy="save-user-button"
                >
                    Discard Changes
                </button>
            </div>
            {/* Delete Button */}
            <div className="flex justify-end">
                <button
                    className="btn bg-rose-500 hover:bg-rose-600 text-white"
                    onClick={() => setIsOpen(false)}
                    data-cy="delete-user-button"
                >
                    Close
                </button>
            </div>
        </div>
    </ModalBasic>
    );
  }