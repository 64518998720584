/**
 * Custom button for signing out.
 */
import { useAuth } from '../../hooks/useAuth';

export default function SignoutButton(): JSX.Element {
  const auth = useAuth();

  async function handleSignout(): Promise<void> {
    try {
      await auth.signOut();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <button
      className="font-medium text-sm flex items-center py-1 px-3 text-indigo-600 dark:text-indigo-400"
      onClick={() => {
        handleSignout().catch((error) => {
          console.error(error);
        });
      }}
    >
      Sign Out
    </button>
  );
}
