/**
 * Container component for the detection table.
 */
import { useState, useEffect } from 'react';

import type { TableColumn } from '../../types/TableColumn';
import { sortDataByColumn } from '../../utils/tables';
import { getDetections } from '../../utils/api';
import { getRows, getColumns } from '../../utils/detections';
import type { RowType } from '../../utils/detections';
import { useData } from '../../hooks/useData';
import TableLoader from './TableLoader';
import TableContainer from './TableContainer';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TableHeader from './TableHeader';
import Table from './Table';
import DownloadButton from '../buttons/DownloadButton';
import RefreshButton from '../buttons/RefreshButton';
import DownloadCsvModal from '../modals/ModalDownloadCsv';
import DropdownLocationSingle from '../dropdowns/DropdownLocationSingle';

export default function DetectionsTable(): JSX.Element {
  const {
    selectedFranchise,
    selectedDates,
    userLocations,
    toggled,
    selectedLocation,
    setSelectedLocation,
  } = useData();
  const [rows, setRows] = useState<RowType[]>([]);
  const [columns, setColumns] = useState<TableColumn[]>([]);
  const [sortColumn, setSortColumn] = useState<TableColumn>({
    label: 'Arrival Time',
    type: 'date',
    key: 'arrivalTime',
  });
  const [sortDirection, setSortDirection] = useState<number>(1);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Fetch the detection data when the selected date changes.
  async function fetchDetectionData(): Promise<void> {
    if (selectedLocation === null) {
      return;
    }

    setIsLoading(true);

    const data = await getDetections(
      selectedDates[0],
      selectedDates[0],
      [selectedLocation.id],
      toggled === 'drive-thru',
      selectedFranchise?.id
    );
    const columns = getColumns(data);
    const unsortedData = getRows(data);
    setColumns(columns);
    if (columns.length === 0) {
      setIsLoading(false);
      setRows(unsortedData);
      return;
    }
    handleSort(unsortedData, columns[0], -1);

    setIsLoading(false);
  }

  // Fetch data on load.
  useEffect(() => {
    if (selectedLocation === null && userLocations.length > 0) {
      setSelectedLocation(userLocations[0]);
    }
  }, [userLocations]);

  // Fetch the detection data when the selected location changes.
  useEffect(() => {
    fetchDetectionData().catch((err) => {
      console.error(err);
    });
  }, [selectedLocation, selectedDates[0], toggled]);

  // Set selected location to the first available when changing franchise.
  useEffect(() => {
    const franchiseLocations = userLocations.filter(
      (location) => location.franchiseId === selectedFranchise?.id
    );
    if (franchiseLocations.length > 0) {
      setSelectedLocation(franchiseLocations[0]);
    }
  }, [selectedFranchise]);

  // Sort the data by the given column.
  function handleSort(
    rows: RowType[],
    column: TableColumn,
    sortDirection: number
  ): void {
    const sortedData = sortDataByColumn(rows, column, sortDirection);
    setRows(sortedData);
    setSortColumn(column);
    setSortDirection(sortDirection * -1);
  }

  // Handle refreshing.
  async function handleRefresh(): Promise<void> {
    await fetchDetectionData();
  }

  const tableTitle = 'Data';

  if (selectedFranchise === null || selectedLocation === null || isLoading) {
    return (
      <TableLoader
        columns={['Arrival Time', 'Order', 'Payment', 'Leave', 'Total Time']}
        title={tableTitle}
        handleRefresh={() => {
          handleRefresh().catch((err) => {
            console.error(err);
          });
        }}
      />
    );
  }

  return (
    <>
      <TableContainer dataCy="detections-table">
        <TableHeader title="Data">
          {/* Dropdown */}
          <DropdownLocationSingle />
          {/* Download Button */}
          <DownloadButton
            onClick={() => {
              setShowDownloadModal(true);
            }}
          />
          {/* Refresh Button */}
          <RefreshButton
            handleRefresh={() => {
              handleRefresh().catch((err) => {
                console.error(err);
              });
            }}
            dataCy="refresh-button-detections"
          />
        </TableHeader>
        <Table>
          <TableHead
            columns={columns}
            handleSort={(col) => {
              handleSort(rows, col, sortDirection);
            }}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
          />
          <TableBody
            columns={columns}
            data={rows}
            location={selectedLocation}
          />
        </Table>
      </TableContainer>
      <DownloadCsvModal
        isOpen={showDownloadModal}
        setIsOpen={setShowDownloadModal}
      />
    </>
  );
}
