import { useCamera } from '../../hooks/useCamera';
export default function CvToggleButton({
  type,
}: {
  type: 'DT' | 'LOBBY' | 'MONITOR';
}): JSX.Element {
  const { 
    showCvStream, 
    setShowCvStream, 
    showCvDtStream, 
    setShowCvDtStream, 
    showMonitorStream, 
    setShowMonitorStream
  } = useCamera();

  function showStream(): boolean {
    if (type === 'DT') {
      return showCvDtStream;
    } else if (type == 'LOBBY'){
      return showCvStream;
    } else {
      return showMonitorStream;
    }
  }

  function setShowStream(b: boolean): void {
    if (type === 'DT') {
      setShowCvDtStream(b);
    } else if (type == 'LOBBY'){
      setShowCvStream(b);
    } else {
      setShowMonitorStream(b);
    }
  }

  return (
    <button
      className={`btn sm:w-auto w-6/12 border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600 ${
        showStream() ? 'bg-gray-300' : ''
      }`}
      onClick={() => {
        setShowStream(!showStream());
      }}
    >
      {type === 'DT' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-car"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <circle cx="7" cy="17" r="2"></circle>
          <circle cx="17" cy="17" r="2"></circle>
          <path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5"></path>
        </svg>
      )}
      {type === 'MONITOR' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-tv"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <rect x="3" y="7" width="18" height="13" rx="2"></rect>
          <polyline points="16 3 12 7 8 3"></polyline>
        </svg>
      )}
      {type === 'LOBBY' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-users"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <circle cx="9" cy="7" r="4"></circle>
          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
        </svg>
      )}
    </button>
  );
}
