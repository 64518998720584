/**
 * Presentational component for updating the day parts.
 */

import SectionTitle from './SectionTitle';
import type { DayPart } from '../../../../types/DayPart';
import TimeSelect from '../../../time/TimeSelect';
import StandardButton from '../../../buttons/StandardButton';

export default function UpdateDayPartsSection({
  dayParts,
  handleInputChange,
  handleTimeChange,
  handleAdd,
  handleDelete,
  handleSave,
}: {
  dayParts: DayPart[];
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleTimeChange: (name: string, dateStr: string, isFrom: boolean) => void;
  handleAdd: () => void;
  handleDelete: (name: string) => void;
  handleSave: () => void;
}): JSX.Element {
  return (
    <section>
      <SectionTitle title="Update Day Parts" />
      <div className="flex flex-col gap-4">
        {dayParts.map((dayPart) => {
          return (
            <div
              key={dayPart.name}
              className="flex flex-wrap w-full gap-4 border-b-2 border-gray py-4"
            >
              {/* Name */}
              <div className="flex flex-col">
                <label className="form-label">Name</label>
                <input
                  className="form-input"
                  placeholder={dayPart.name}
                  onChange={handleInputChange}
                  name={`${dayPart.name}-name`}
                  data-cy="day-part-name-input"
                  value={dayPart.name}
                />
              </div>
              {/* Start Time */}
              <div className="flex flex-col">
                <label className="form-label">Start Time</label>
                <TimeSelect
                  onChange={(dateStr: string) => {
                    handleTimeChange(dayPart.name, dateStr, true);
                  }}
                  dataCy="day-part-start-time-input"
                  value={dayPart.from}
                />
              </div>
              {/* End Time */}
              <div className="flex flex-col">
                <label className="form-label">End Time</label>
                <TimeSelect
                  onChange={(dateStr: string) => {
                    handleTimeChange(dayPart.name, dateStr, false);
                  }}
                  dataCy="day-part-end-time-input"
                  value={dayPart.to}
                />
              </div>
              {/* Target Time */}
              <div className="flex flex-col">
                <label className="form-label">Target Time</label>
                <input
                  className="form-input"
                  type="number"
                  onChange={handleInputChange}
                  name={`${dayPart.name}-targetTime`}
                  data-cy="day-part-target-time-input"
                  value={dayPart.targetTime}
                />
              </div>
              {/* Target Percentage */}
              <div className="flex flex-col">
                <label className="form-label">Target Percentage</label>
                <input
                  className="form-input"
                  type="number"
                  onChange={handleInputChange}
                  name={`${dayPart.name}-percentage`}
                  data-cy="day-part-target-percentage-input"
                  value={dayPart.percentage}
                />
              </div>
              {/* Delete Button */}
              <div className="flex flex-col">
                <label>Delete</label>
                <button
                  className="btn bg-rose-500 hover:bg-rose-600 text-white"
                  onClick={() => {
                    handleDelete(dayPart.name);
                  }}
                  data-cy="delete-day-part-button"
                >
                  Delete
                </button>
              </div>
            </div>
          );
        })}
        <div className="flex flex-wrap gap-8 w-full">
          <StandardButton onClick={handleAdd} dataCy="add-new-day-part-button">
            Add New Day Part
          </StandardButton>
          <StandardButton onClick={handleSave} dataCy="update-day-part-button">
            Save Updates
          </StandardButton>
        </div>
      </div>
    </section>
  );
}
