/**
 * Presentational component for pausing and skipping videos.
 */
/* eslint-disable multiline-ternary */

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Replay5Icon from '@mui/icons-material/Replay5';
import Forward5Icon from '@mui/icons-material/Forward5';
import { ButtonGroup, IconButton } from '@mui/material';
import { useCamera } from '../../hooks/useCamera';

export default function PauseSkipButton(): JSX.Element {
  const { isLive, videoDateTime, setVideoDateTime, isPlaying, setIsPlaying } =
    useCamera();

  // Handle rewinding the video.
  function rewind(): void {
    const newDate = new Date(videoDateTime.getTime() - 5000);
    setVideoDateTime(newDate);
  }

  // Handle fast forwarding the video.
  function fastForward(): void {
    const newDate = new Date(videoDateTime.getTime() + 5000);
    setVideoDateTime(newDate);
  }

  return (
    <div className="flex justify-center">
      <div className="m-3 bg-white dark:bg-slate-800 shadow-lg rounded-lg p-2 ">
        <ButtonGroup>
          <IconButton
            onClick={rewind}
            disabled={isLive}
            data-cy="rewind-5-button"
          >
            <Replay5Icon className="dark:text-slate-400" />
          </IconButton>

          <IconButton
            onClick={() => {
              setIsPlaying(!isPlaying);
            }}
            data-cy="pause-play-button"
          >
            {isPlaying ? (
              <PauseIcon data-cy="play-icon" className="dark:text-slate-400" />
            ) : (
              <PlayArrowIcon
                data-cy="pause-icon"
                className="dark:text-slate-400"
              />
            )}
          </IconButton>
          <IconButton
            onClick={fastForward}
            disabled={isLive}
            data-cy="skip-5-button"
          >
            <Forward5Icon className="dark:text-slate-400" />
          </IconButton>
        </ButtonGroup>
      </div>
    </div>
  );
}
