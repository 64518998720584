/**
 * Card component for a camera to be ordered.
 */
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

export default function CameraCard({
  id,
  moveCard,
}: {
  id: string;
  moveCard: (draggedId: string, targetId: string) => void;
}): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const cardType = 'card';

  const [{ isDragging }, connectDrag] = useDrag({
    type: cardType,
    item: { id },
    collect: (monitor) => {
      const result = {
        handlerId: monitor.getHandlerId(),
        isDragging: monitor.isDragging(),
      };
      return result;
    },
  });

  const [, connectDrop] = useDrop({
    accept: cardType,
    hover({ id: draggedId }: { id: string; type: string }) {
      if (draggedId !== id) {
        moveCard(draggedId, id);
      }
    },
  });

  connectDrag(ref);
  connectDrop(ref);

  return (
    <div
      ref={ref}
      className={`border border-gray-300 rounded-md p-2 m-2 ${
        isDragging ? 'opacity-50' : ''
      }`}
    >
      {id}
    </div>
  );
}
