/**
 * Presentational component for a help button.
 */

import IconButton from './IconButton';
import HelpIcon from '@mui/icons-material/Help';

export default function HelpButton({
  onClick,
}: {
  onClick: () => void;
}): JSX.Element {
  return (
    <IconButton
      onClick={onClick}
      icon={<HelpIcon />}
    />
  );
}
