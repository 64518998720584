/**
 * Presentational component for changing playback speed.
 */

import SpeedIcon from '@mui/icons-material/Speed';
import { useCamera } from '../../hooks/useCamera';

export default function PlaybackSpeedControls(): JSX.Element {
  const speeds = [1, 2, 5, 10, 15];
  const { playbackSpeed, setPlaybackSpeed, isLive } = useCamera();

  return (
    <div className="flex items-center justify-center">
      <div className="m-3 flex flex-row bg-white dark:bg-slate-800 shadow-lg rounded-lg p-2 items-center">
        <SpeedIcon className="mr-2" />
        <div className="inline-flex">
          {speeds.map((speed) => {
            return (
              <button
                key={speed}
                className={`btn ${
                  playbackSpeed === speed
                    ? 'bg-gray-200 dark:bg-slate-700'
                    : 'bg-white dark:bg-slate-800'
                } focus:outline-none ${isLive ? 'cursor-not-allowed' : ''} ${
                  isLive ? '' : 'hover:bg-gray-100'
                }`}
                onClick={() => {
                  setPlaybackSpeed(speed);
                }}
                disabled={isLive}
                data-cy={`speed-${speed}-button`}
              >
                {speed}X
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
