import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import ThemeProvider from './utils/ThemeContext';
import App from './App';
import { ProvideData } from './hooks/useData';
import { ProvideCamera } from './hooks/useCamera';
import { ProvideAuth } from './hooks/useAuth';
import { ProvideToast } from './hooks/useToast';
import { ProvideProfile } from './hooks/useProfile';
import { ProvideLoad } from './hooks/useLoad';
import AppLayout from './components/ui/AppLayout';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ProvideAuth>
      <ProvideData>
        <ProvideToast>
          <ProvideProfile>
            <ProvideCamera>
              <ProvideLoad>
                <AppLayout>
                  <Router>
                    <ThemeProvider>
                      <App />
                    </ThemeProvider>
                  </Router>
                </AppLayout>
              </ProvideLoad>
            </ProvideCamera>
          </ProvideProfile>
        </ProvideToast>
      </ProvideData>
    </ProvideAuth>
  </React.StrictMode>
);
