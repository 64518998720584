import { useCallback, useEffect, useState } from "react";
import { mapDataAreaToShape, mapLabelmeToDatashare } from "../../utils/loader";
import { Size } from "../../interfaces/size";
import { ImageData } from "../../interfaces/imageData";
import { DataArea } from "../../interfaces/dataArea";
import { Labelme } from "../../interfaces/labelme";
import Box from "../Box/box";
import TooltipButton from "../TooltipButton/tooltipButton";
import { FaFileArrowUp, FaFileExport, FaTrashCan, FaUpload } from "react-icons/fa6";
import TooltipSubmit from "../TooltipSubmit/tooltipSubmit";
import { ReservedKeyword } from "../../interfaces/reservedKeyword";
import TooltipDropdown from "../TooltipDropdown/tooltipDropdown";
import TooltipDropdownOption from "../TooltipDropdownOption/tooltipDropdownOption";

import '../../css/labelme.css';
import TooltipButtonScroll from "../TooltipButtonScroll/tooltipButtonScroll";

export interface LoaderProps {
    dataAreas: Array<DataArea> | undefined;
    canvasSize: Size<number> | undefined;
    configuration: ReservedKeyword;
    fileName: string;
    loadedImages: Map<string, string>;

    setFileName: (fileName: string) => void; // Update to accept a string argument
    loadImageData: (data: ImageData | undefined) => void;
    loadDataAreas: (data: Array<DataArea> | undefined) => void;
    exportDataArea: (labelme: Labelme, canvasSize: Size<number>) => void;
};

export const Loader = (props: LoaderProps) => {
    // Configuration
    const defaultImageSize: Size<number> = {
        width: 1280,
        height: 720
    };

    
    // States
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [imageData, setImageData] = useState<ImageData | undefined>(undefined);

    
    const loadImageFromUrl = async (url: string) => {
        setError(false);
        try {
            const data = await getImageData(url);
            setImageData({
                name: url,
                size: {
                    width: data.width,
                    height: data.height
                },
                urlResource: url
            });
        } catch (err) {
            console.error(err);
            setError(true);
            setErrorMessage("Failed to load image. Are you sure submitted URL is valid?");
        }
    };
    
    const getImageData = (imageUrl: string): Promise<Size<number>> => {
        return new Promise<Size<number>>((resolve, reject) => {
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => {
                resolve({ 
                    width: img.naturalWidth, 
                    height: img.naturalHeight
                });
            };
            img.onerror = (err) => {
                console.error(err);
                reject(new Error(`Failed to load image`));
                setError(true);
                setErrorMessage("Failed to load image. Are you sure submitted URL is valid?");
            };
        });
    };
    
    const exportDataAreas = (newDesiredSize?: Size<number>): Boolean => {
        if (props.dataAreas && props.canvasSize && imageData) {
            props.exportDataArea(
                {
                    version: "4.6.0",
                    flags: {},
                    shapes: props.dataAreas.map((dataArea) => mapDataAreaToShape(dataArea, imageData, newDesiredSize)),
                    imageWidth: newDesiredSize?.width ?? imageData.size.width,
                    imageHeight: newDesiredSize?.height ?? imageData.size.height
                }, 
                props.canvasSize
            );
        }
        return false;
    };

    const loadData = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setError(false)
            const dataFile = e.target.files[0];
            const filaReader = new FileReader();
            filaReader.onload = (e) => {
                if (e.target) {
                    const fileContent = e.target.result;
                    if (fileContent) {
                        const toJson: Labelme = JSON.parse(fileContent.toString()!);
                        const dataAreas: Array<DataArea> = mapLabelmeToDatashare(
                            toJson, 
                            props.canvasSize ?? defaultImageSize,
                            props.configuration
                        );
                        props.loadDataAreas(dataAreas);
                    }
                }
            }
            filaReader.readAsText(dataFile);
        }
    }


    const loadDataFromUrl = async (url: string | undefined) => {
        try {
          setError(false);
      
          // Fetch the JSON data from the URL
          const response = await fetchJsonData(url);
          console.log('response', response);
      
          // Since response is already a JSON object, no need to call response.text()
          const toJson: Labelme = response;
      
          // Map the parsed data to the desired structure
          const dataAreas: Array<DataArea> = mapLabelmeToDatashare(
            toJson, 
            props.canvasSize ?? defaultImageSize,
            props.configuration
          );
      
          // Pass the mapped data to the loadDataAreas function
          props.loadDataAreas(dataAreas);
        } catch (error) {
          console.error("Error loading data from URL:", error);
          setError(true);
        }
      };
      



    const fetchJsonData = async (url: any) => {      
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          return data; // This will return the fetched data
        } catch (error) {
          console.error('Error fetching data:', error);
          return null; // Return null in case of an error
        }
      };
      
 
    const deleteImageData = () => {
        setImageData(undefined);
    };

    useEffect(() => {
        props.loadImageData(imageData);
    }, [ imageData ]);

    const imageClicked = useCallback((key: string) => {
        const img = props.loadedImages.get(key);
        if (img) {
            const imageData: ImageData = {
                name: key,
                size: { width: 1280, height: 720 }, // Set appropriate size
                urlResource: img
            };
            setImageData(imageData);
        }
    }, [props.loadedImages]);

    // Additional buttons
    const buttonNames = ["enter", "enter2", "exit", "pull", "pull1", "lobby_areas", "mobile"];

    async function findRelevantData(name: string) {
        // Concatenate the name with ".json"
        const keyFragment = name + ".json";
    
        // Iterate over the keys of the map
        for (let key of props.loadedImages.keys()) {
            // Check if the key contains the keyFragment
            if (key.includes(keyFragment)) {
                // Return the value associated with the key
                loadDataFromUrl(props.loadedImages.get(key))
                let dataToLoad = fetchJsonData(props.loadedImages.get(key))
                loadData(dataToLoad)
            }
        }
    
        // If no matching key is found, return undefined
    }

    return (
        <div className="w-100">
            <div className="row mb-2">
                <div className = "col-12 d-flex" style={{justifyContent: "space-between"}}>

                    <div className="w-100 mr-2">
                        <TooltipSubmit 
                            text="Submit File"
                            fontColor = "white"
                            icon = { FaFileArrowUp }
                            padding = "10px 20px"
                            backgroundColor="#484F56"
                            borderColor="#3d3d3d"
                            hoverColor = "#5d5d5d"
                            disabled = { false }
                            accept = "image/*"
                            onChange={ loadImageFromUrl } 
                        />
                    </div>

                    <div className="w-100 mr-2">
                        <TooltipSubmit 
                            text="Load Labels"
                            fontColor = "white"
                            padding = "10px 20px"
                            icon = { FaFileArrowUp }
                            backgroundColor = "#1dd1a1"
                            borderColor = "#10ac84"
                            hoverColor = "#55efc4"
                            disabled = { imageData ? false : true }
                            accept = ".json"
                            onChange={ loadData } 
                        />
                    </div>

                    <div className="w-100 mr-2">
                        <TooltipButtonScroll 
                            text="Export Data"
                            fontColor="white"
                            padding="10px 20px"
                            icon={FaFileExport}
                            backgroundColor="#54a0ff"
                            borderColor="#2e86de"
                            hoverColor="#74b9ff"
                            disabled={props.dataAreas && props.dataAreas.length <= 0 ? true : false}
                            onClick={() => {
                                exportDataAreas({
                                    width: 1280,
                                    height: 720
                                });
                            }}
                            scrollToId="jsonViewer" // the ID of the element to scroll to
                        />
                    </div>
{/* 
                    <div className="w-100 mr-2">
                        <TooltipDropdown 
                            text="Export Data"
                            padding = "10px 20px"
                            fontColor = "white"
                            icon = { FaFileExport }
                            backgroundColor = "#54a0ff"
                            borderColor = "#2e86de"
                            hoverColor = "#74b9ff"
                            disabled = { props.dataAreas && props.dataAreas.length <= 0 ? true : false }
                        >
                            <TooltipDropdownOption 
                                text = {imageData ? `${imageData.size.width} x ${imageData.size.height}` : "Original size"}
                                padding = "10px 20px"
                                fontColor = "white"
                                backgroundColor = "#54a0ff"
                                borderColor = "#2e86de"
                                hoverColor = "#74b9ff"
                                onClick={ exportDataAreas } 
                            />

                            <TooltipDropdownOption 
                                text="1280 x 720"
                                padding = "10px 20px"
                                fontColor = "white"
                                backgroundColor = "#54a0ff"
                                borderColor = "#2e86de"
                                hoverColor = "#74b9ff"
                                onClick={() => {exportDataAreas({
                                    width: 1280,
                                    height: 720
                                })}} 
                            />

                        </TooltipDropdown>
                    </div> */}

                    <TooltipButton 
                        text="Delete Image"
                        padding = "10px 20px"
                        fontColor = "white"
                        icon = { FaTrashCan }
                        backgroundColor="#ff6b6b"
                        borderColor="#ee5253"
                        hoverColor = "#fc5c65"
                        disabled={ imageData ? false : true }
                        onClick={deleteImageData} 
                    /> 
                </div>
            </div>

            <div className="row mb-2" style={{ display: 'flex', flexWrap: 'wrap' }}>
                {buttonNames.map((name) => (
                    <div className="mr-2" key={name}>
                        <TooltipButton 
                            text={name}
                            padding="10px 20px"
                            fontColor="white"
                            backgroundColor="#2e86de"
                            borderColor="#1d6fa5"
                            hoverColor="#4a90d9"
                            onClick={() => {
                                props.setFileName(name);
                                findRelevantData(name);
                            }}
                            disabled={false}
                        /> 
                    </div>
                ))}
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="">
                        {
                            error
                                ? <Box text={`${errorMessage}`} backgroundColor="rgba(231,76,60,0.5)" borderColor="rgba(192,57,43,0.8)"/>
                                : imageData 
                                    ? (
                                        <Box 
                                            text={`Loaded image: ${imageData.name}`} 
                                            backgroundColor="#2e3440"
                                        />
                                    )
                                    : <></>
                        }
                    </div>
                </div>
            </div>
            

            <div>
                {Array.from(props.loadedImages.entries())
                    .filter(([key, url]) => key.endsWith('.png')) // Filter only .png files
                    .map(([key, url]) => (
                        <div className="row" key={key} onClick={() => imageClicked(key)}>
                            <div className="col-10 col-m-10 p-2 mx-auto">
                                <h3>{key}:</h3>
                                <img src={url} alt={key} />
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
};

export default Loader;
