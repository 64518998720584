/**
 * A link that displays the departure time of a detection.
 */
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';

import type { Location } from '../../types/Location';
import { utcStringToLocalDate } from '../../utils/time';

export default function ArrivalTimeLink({
  utcTime,
  location,
  setDate,
  handleClick,
  setIsLive,
}: {
  utcTime: string;
  location: Location;
  setDate?: (date: Date) => void;
  handleClick?: () => void;
  setIsLive?: (isLive: boolean) => void;
}): JSX.Element {
  // Unpack the date.
  const localDate = utcStringToLocalDate(utcTime, location.timezone);
  const valueDate = new Date(localDate.valueOf());
  const formattedDate = dateFormat(localDate, 'mediumTime');
  // Create the query parameters for the link.
  const params = {
    dt:
      dateFormat(valueDate, 'yyyy-mm-dd') +
      'T' +
      dateFormat(valueDate, 'HH:MM'),
    lid: location.id.toString(),
  };
  const urlSearchParams = new URLSearchParams(params);
  const url = '/cameras?' + urlSearchParams.toString();
  return (
    <Link
      className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
      to={url}
      onClick={() => {
        if (setIsLive !== undefined) setIsLive(false);
        if (setDate !== undefined) setDate(valueDate);
        if (handleClick !== undefined) handleClick();
      }}
    >
      {formattedDate}
    </Link>
  );
}
