/**
 * Container component for the detection table.
 */
import { useState, useEffect } from 'react';

import type { TableColumn } from '../../types/TableColumn';
import { getRegionOrder, sortDataByColumn } from '../../utils/tables';
import { getDetections } from '../../utils/api';
import { getRows } from '../../utils/detections';
import type { RowType } from '../../utils/detections';
import { useData } from '../../hooks/useData';
import TableLoader from './TableLoader';
import TableContainer from './TableContainer';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TableHeader from './TableHeader';
import Table from './Table';
import DownloadButton from '../buttons/DownloadButton';
import RefreshButton from '../buttons/RefreshButton';
import DownloadCsvModal from '../modals/ModalDownloadCsv';
import DropdownLocationSingle from '../dropdowns/DropdownLocationSingle';
import { useCamera } from '../../hooks/useCamera';
import { Detection } from '../../types/Detection';
import { Column } from '../../types/Column';
import { utcStringToLocalDate } from '../../utils/time';

export default function CameraDetectionsTable(): JSX.Element {
  const {
    selectedFranchise,
    toggled,
    selectedLocation
  } = useData();
  const {
    videoDateTime
  } = useCamera();
  const [rows, setRows] = useState<RowType[]>([]);
  const [columns, setColumns] = useState<TableColumn[]>([]);
  const [sortColumn, setSortColumn] = useState<TableColumn>({
    label: 'Arrival Time',
    type: 'date-local',
    key: 'arrivalTime',
  });
  const [sortDirection, setSortDirection] = useState<number>(1);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Get the columns from the data.
function getColumns(rawData: Detection[]): Column[] {
  if (rawData.length === 0) {
    return [];
  }
  const regions: Record<string, string> = {};
  let cols = [];
  // Get all possible regions.
  for (const data of rawData) {
    for (const region of data.regions) {
      if (region.timeSpent) {
        regions[region.key] = region.displayName;
      }
    }
  }
  for (const key in regions) {
    cols.push({
      label: regions[key],
      type: 'seconds',
      key,
    });
  }
  // sort columns
  cols = getRegionOrder(cols);
  // set first and last columns
  cols = [
    {
      label: 'Arrival Time',
      key: 'arrivalTime',
      type: 'date-local',
    },
    {
      label: 'Model ID',
      key: 'modelId',
      type: 'number',
    },
    ...cols,
    {
      label: 'Total Time',
      key: 'totalTime',
      type: 'seconds',
    }
  ];
  return cols;
}

function areDatesOnSameDay(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}


  // Fetch the detection data when the selected date changes.
  async function fetchDetectionData(): Promise<void> {
    if (selectedLocation === null) {
      return;
    }
    if (rows.length > 0) {
      const localDate = utcStringToLocalDate(rows[0].arrivalTime, selectedLocation.timezone);
      const valueDate = new Date(localDate.valueOf());
      // Check if both dates are on the same day
      if (areDatesOnSameDay(valueDate, videoDateTime)) {
        console.log("Both dates are on the same day");
        return;
      } else {
        console.log("Dates are not on the same day, refreshing data");
      }
    }

    setIsLoading(true);

    const data = await getDetections(
      videoDateTime,
      videoDateTime,
      [selectedLocation.id],
      toggled === 'drive-thru'
    );
    const columns = getColumns(data);
    const unsortedData = getRows(data);
    setColumns(columns);
    if (columns.length === 0) {
      setIsLoading(false);
      setRows(unsortedData);
      return;
    }
    handleSort(unsortedData, columns[0], -1);

    setIsLoading(false);
  }

  // Fetch the detection data when the selected location changes.
  useEffect(() => {
    fetchDetectionData().catch((err) => {
      console.error(err);
    });
  }, [selectedLocation, videoDateTime, toggled]);

  // Sort the data by the given column.
  function handleSort(
    rows: RowType[],
    column: TableColumn,
    sortDirection: number
  ): void {
    const sortedData = sortDataByColumn(rows, column, sortDirection);
    setRows(sortedData);
    setSortColumn(column);
    setSortDirection(sortDirection * -1);
  }

  // Handle refreshing.
  async function handleRefresh(): Promise<void> {
    await fetchDetectionData();
  }

  const tableTitle = 'Data';

  if (selectedFranchise === null || selectedLocation === null || isLoading) {
    return (
      <TableLoader
        columns={['Arrival Time', 'Order', 'Payment', 'Leave', 'Total Time']}
        title={tableTitle}
        handleRefresh={() => {
          handleRefresh().catch((err) => {
            console.error(err);
          });
        }}
      />
    );
  }

  return (
    <>
      <TableContainer dataCy="detections-table">
        <TableHeader title="Data">
          {/* Dropdown */}
          <DropdownLocationSingle />
          {/* Download Button */}
          <DownloadButton
            onClick={() => {
              setShowDownloadModal(true);
            }}
          />
          {/* Refresh Button */}
          <RefreshButton
            handleRefresh={() => {
              handleRefresh().catch((err) => {
                console.error(err);
              });
            }}
            dataCy="refresh-button-detections"
          />
        </TableHeader>
        <Table>
          <TableHead
            columns={columns}
            handleSort={(col) => {
              handleSort(rows, col, sortDirection);
            }}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
          />
          <TableBody
            columns={columns}
            data={rows}
            location={selectedLocation}
          />
        </Table>
      </TableContainer>
      <DownloadCsvModal
        isOpen={showDownloadModal}
        setIsOpen={setShowDownloadModal}
      />
    </>
  );
}
