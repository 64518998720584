/**
 * Presentational component for custom table header. This component goes above the table,
 * as opposed to the TableHead, which will display the columns that go inside the table.
 */
export default function TableHeader({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}): JSX.Element {
  return (
    <div className="px-5 py-4 border-b bg-white dark:bg-[#182235] border-slate-200 dark:border-slate-700 text-gray-800 dark:text-white rounded-t-lg">
      <div>
        <h2 className="font-semibold inline">{title}</h2>
        <div className="flex flex-wrap gap-2 items-center justify-center float-right">
          {children}
        </div>
      </div>
    </div>
  );
}
