
import { DriveOffData } from "@/types/Stack";
import { DriveOff, columns } from "./DriveOffsColumn"
import { DriveOffTableComponent } from "@/components/tables/DriveOffTableComponent"
import { useData } from "@/hooks/useData";

function setData(driveOffsData: DriveOffData[]): DriveOff[]{
    const {
        selectedLocations,
      } = useData();
  // Fetch data from your API here.
  const result = driveOffsData
  .filter(data => selectedLocations.some(loc => loc.id === data.LocationID))
  .map(data => ({
    timeStamp: data.TimeStamp,
    location: selectedLocations.find(loc => loc.id === data.LocationID)
  }));
  return result as DriveOff[]
}

export default function DriveOffTable({
  driveOffsData,
}: {
  driveOffsData: DriveOffData[] | undefined;
}) {
    if (driveOffsData){
        const data = setData(driveOffsData)

        return (
            <DriveOffTableComponent columns={columns} data={data} />
        )}
    return <></>
}
