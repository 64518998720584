/**
 * Main Dashboard Page
 * This page is intended to allow the customer to view
 * a single day's worth of data from any of their locations.
 */
import { useEffect } from 'react';
import PageLayout from '../../components/ui/PageLayout';
import DropdownFranchise from '../../components/dropdowns/DropdownFranchise';
import DropdownLocationMultiple from '../../components/dropdowns/DropdownLocationMultiple';
import Datepicker from '../../components/time/Datepicker';
import DriveThruLobbyToggle from '../../components/buttons/DriveThruLobbyToggle';
import DayPartsTable from '../../components/tables/DayPartsTable';
import RanksTable from '../../components/tables/RanksTable';
import StationsTable from '../../components/tables/StationsTable';
import DetectionTable from '../../components/tables/DetectionsTable';
import { useData } from '../../hooks/useData';

function Dashboard(): JSX.Element {
  const { selectedDates, setSelectedDates } = useData();

  // Set dates on load.
  useEffect(() => {
    const today = new Date();
    setSelectedDates([today, today]);
  }, []);

  return (
    <PageLayout>
      <div data-cy="dashboard">
        {/* Dashboard actions */}
        <div className="sm:flex sm:justify-between sm:items-center mb-8 gap-4">
          {/* Left: Franchise and Location Selector */}
          <div className="flex flex-wrap justify-start gap-2 mb-4">
            {/* Franchise Selector */}
            <DropdownFranchise />
            {/* Location Selector */}
            <DropdownLocationMultiple dataCy="dashboard-location-selector" />
          </div>
          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* Datepicker built with flatpickr */}
            <Datepicker
              initDates={selectedDates}
              handleDateChange={setSelectedDates}
              mode="single"
            />
            {/* Toggles for lobby and drive-thru */}
            <DriveThruLobbyToggle />
          </div>
        </div>
        {/* Tables */}
        <div className="flex flex-col gap-4">
          <RanksTable />
          <DayPartsTable />
          <StationsTable />
          <DetectionTable />
        </div>
      </div>
    </PageLayout>
  );
}

export default Dashboard;
