import { useState } from 'react';

import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';
import AuthImage from '../../../components/auth/AuthImage';
import AuthContainer from '../../../components/auth/AuthContainer';

function ForgotPasswordRequest(): JSX.Element {
  const [email, setEmail] = useState('');
  const { setToast } = useToast();
  const { sendResetPasswordEmail } = useAuth();

  // Handle input change
  function onEmailChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(e.target.value);
  }

  // Handle reset password
  async function handleSendCode(): Promise<void> {
    if (email === '') {
      setToast({
        show: true,
        message: 'Email is required',
        type: 'error',
      });
      return;
    }

    try {
      await sendResetPasswordEmail(email);
      window.location.replace(`/forgot-password/submit?email=${email}`);
    } catch (error: any) {
      console.log('error', error);
      setToast({
        show: true,
        message: error.message,
        type: 'error',
      });
    }
  }

  return (
    <main className="bg-white dark:bg-slate-900">
      <AuthContainer>
        <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">
          Reset your Password ✨
        </h1>
        {/* Form */}

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="email">
              Email Address <span className="text-rose-500">*</span>
            </label>
            <input
              id="email"
              name="email"
              className="form-input w-full"
              type="email"
              onChange={onEmailChange}
            />
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <button
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap"
            onClick={() => {
              handleSendCode().catch((err) => {
                console.log(err);
              });
            }}
          >
            Reset Password
          </button>
        </div>
      </AuthContainer>

      {/* Image */}
      <AuthImage />
    </main>
  );
}

export default ForgotPasswordRequest;
