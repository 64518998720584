/**
 * Presentational component for adding and removing cameras.
 */
import { useCamera } from '../../hooks/useCamera';

export default function CameraPlusMinus(): JSX.Element {
  const { numCamerasDisplayed, setNumCamerasDisplayed, availableCameras } =
    useCamera();
  const maxCameras = availableCameras.length - 1;

  return (
    <div className="custom-number-input w-32">
      <div className="flex flex-row w-full rounded-lg relative bg-transparent border border-slate-200 dark:border-none">
        <button
          onClick={() => {
            if (numCamerasDisplayed > 1) {
              setNumCamerasDisplayed(numCamerasDisplayed - 1);
            }
          }}
          className="bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-white hover:text-gray-700 hover:bg-gray-300 w-20 rounded-l cursor-pointer outline-none"
          data-cy="remove-camera-button"
        >
          <span className="m-auto text-2xl">−</span>
        </button>
        <input
          type="number"
          className="border-0 outline-0 text-center w-full text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700 dark:text-white bg-white dark:bg-slate-800"
          name="custom-input-number"
          value={numCamerasDisplayed}
          readOnly
          data-cy="num-cameras-input"
        />
        <button
          onClick={() => {
            if (numCamerasDisplayed < maxCameras) {
              setNumCamerasDisplayed(numCamerasDisplayed + 1);
            }
          }}
          className="bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-white hover:text-gray-700 hover:bg-gray-300 w-20 rounded-r cursor-pointer"
          data-cy="add-camera-button"
        >
          <span className="m-auto text-2xl">+</span>
        </button>
      </div>
    </div>
  );
}
