/**
 * Styling for a button with an icon.
 */

export default function IconButton({
  onClick,
  icon
}: {
    onClick: () => void;
    icon: React.ReactNode;
    }): JSX.Element {
    return (
        <button
        onClick={onClick}
        className="btn bg-white dark:bg-slate-700 focus:outline-none"
        data-cy="edit-button"
        >
        {icon}
        </button>
    );
}
