/**
 * Layout for the app.
 */
import { CircularProgress } from '@mui/material';
import Toast from './Toast';

import { useToast } from '../../hooks/useToast';
import { useLoad } from '../../hooks/useLoad';

export default function AppLayout({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { toast, setToast } = useToast();
  const { isLoading } = useLoad();

  return (
    <>
      {children}
      <div className="absolute bottom-0 left-0">
        {/* Display a loading spinner in the middle of the screen. */}
        {isLoading && (
          <CircularProgress
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
        {/* Display a toast message. */}
        <Toast
          type={toast.type}
          open={toast.show}
          setOpen={(isOpen: boolean) => {
            setToast({ ...toast, show: false });
          }}
        >
          {toast.message}
        </Toast>
      </div>
    </>
  );
}
