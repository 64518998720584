/**
 * Dropdown for selecting multiple regions.
 */
import DropdownFilter from './DropdownFilter';

export default function DropdownRegionMultiple({
  regionStats,
  selectedRegionStats,
  handlSelectedRegionStatsChange,
  dataCy = 'dropdown-region-multiple',
}: {
  regionStats: any[];
  selectedRegionStats: any[];
  handlSelectedRegionStatsChange: any;
  dataCy?: string;
}): JSX.Element {
  return (
    <DropdownFilter
      title={`${selectedRegionStats.length} Regions`}
      options={regionStats}
      selected={selectedRegionStats}
      handleChange={handlSelectedRegionStatsChange}
      dataCy={dataCy}
    />
  );
}
