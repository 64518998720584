/**
 * Container component for the account panel.
 */

import { useState, useEffect } from 'react';
import { patchUserProfile } from '../../../utils/api';
import type { UserProfile } from '../../../types/UserProfile';
import { useData } from '../../../hooks/useData';
import { useToast } from '../../../hooks/useToast';
import { useAuth } from '../../../hooks/useAuth';
import DefaultLocationSection from './sections/DefaultLocationSection';
import LocationNotificationsSection from './sections/LocationNotificationsSection';
import StandardButton from '../../buttons/StandardButton';

export default function AccountPanel(): JSX.Element {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const { setToast } = useToast();
  const { profile } = useData();
  const { signOut } = useAuth();
  const [defaultLocation, setDefaultLocation] = useState(
    userProfile?.locations[0]
  );

  // Set user locations on load.
  useEffect(() => {
    setUserProfile(profile);
    const defaultLocation = userProfile?.locations.find(
      (location) => location.id === localStorage.getItem('defaultLocation')
    );
    if (defaultLocation !== null && defaultLocation !== undefined) {
      setDefaultLocation(defaultLocation);
    }
  }, [profile]);

  // Handle selecting a new default location.
  function handleSelectDefaultLocation(id: string): void {
    const newDefaultLocation = userProfile?.locations.find(
      (location) => location.id === id
    );
    if (newDefaultLocation === undefined || userProfile === undefined) {
      return;
    }
    localStorage.setItem('defaultLocation', newDefaultLocation.id);
    setDefaultLocation(newDefaultLocation);
  }

  // Handle selecting a location to receive notifications for.
  function handleSelectLocationForNotifications(id: string): void {
    const newSelectedLocation = userProfile?.locations.find(
      (location) => location.id === id
    );
    if (newSelectedLocation === undefined || userProfile === null) {
      return;
    }

    if (userProfile.notificationLocations.includes(newSelectedLocation.id)) {
      setUserProfile({
        ...userProfile,
        notificationLocations: userProfile.notificationLocations.filter(
          (locationId) => locationId !== newSelectedLocation.id
        ),
      });

      // Communicate with Flutter app
      console.log(
        JSON.stringify({
          remove_notification: id,
        })
      );
    } else {
      setUserProfile({
        ...userProfile,
        notificationLocations: [
          ...userProfile.notificationLocations,
          newSelectedLocation.id,
        ],
      });

      // Communicate with Flutter app
      console.log(
        JSON.stringify({
          add_notification: id,
        })
      );
    }
  }

  // Handle submitting new notification locations.
  async function handleSaveProfile(): Promise<void> {
    if (userProfile === null) {
      return;
    }
    await patchUserProfile(userProfile.notificationLocations);
    setToast({
      show: true,
      message: 'Profile updated',
      type: 'success',
    });
  }

  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">
          My Account
        </h2>
        {/* Set Default Location */}
        {userProfile !== null && (
          <DefaultLocationSection
            userProfile={userProfile}
            selectedLocation={defaultLocation ?? userProfile.locations[0]}
            handleChange={handleSelectDefaultLocation}
          />
        )}
        {/* Location Notifications */}
        {userProfile !== null && (
          <LocationNotificationsSection
            locations={userProfile?.locations}
            selectedLocations={userProfile.locations.filter((location) =>
              userProfile.notificationLocations.includes(location.id)
            )}
            handleSelect={handleSelectLocationForNotifications}
            handleSave={() => {
              handleSaveProfile().catch((err) => {
                console.error(err);
              });
            }}
          />
        )}
        {/* Sign Out */}
        <StandardButton
          onClick={() => {
            signOut().catch((err) => {
              console.error(err);
            });
          }}
          dataCy="sign-out-button"
        >
          Sign Out
        </StandardButton>
      </div>
    </div>
  );
}
