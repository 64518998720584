/**
 * Utility functions for working with CSV files.
 */

/**
 * @function formatField
 * @description Formats a field for inclusion in a CSV file.
 * @param field
 * @returns
 */
export function formatField(field: any): string {
  if (field === null || field === undefined) {
    return '';
  }

  let formattedField = field.toString() as string;

  // If field contains a double quote, replace it with two double quotes.
  if (formattedField.includes('"')) {
    formattedField = formattedField.replace(/"/g, '""');
  }

  // If field contains a comma, newline, carriage return, or double quote,
  // wrap it in double quotes.
  if (
    formattedField.includes(',') ||
    formattedField.includes('\n') ||
    formattedField.includes('\r') ||
    formattedField.includes('"')
  ) {
    formattedField = `"${formattedField}"`;
  }

  return formattedField;
}

/**
 * @function constructCsv
 * @description Constructs a CSV file with the given data and column labels.
 * @param data
 * @param columns
 * @returns
 */
export function constructCsv(
  data: any[][],
  columns: Array<{ label: string; type?: string }>
): string {
  // Ensure that the number of columns in each row of data matches the number of columns.

  if (data.length > 0) {
    const numColumns = columns.length;
    data.forEach((row) => {
      if (row.length !== numColumns) {
        throw new Error(
          `Number of columns in data row (${row.length}) does not match number of columns (${numColumns}).`
        );
      }
    });
  }

  let csvContent = 'data:text/csv;charset=utf-8,';

  // Add the column labels.
  const columnLabels = columns.map((column) => column.label);
  csvContent += columnLabels.join(',') + '\n';

  // Add the data.
  data.forEach(function (rowArray) {
    const row = rowArray.map(formatField).join(',');
    csvContent += row + '\n';
  });

  csvContent = csvContent.replaceAll('#', '');

  return csvContent;
}

/**
 * @function downloadCsv
 * @description Downloads a CSV file with the given data and column labels.
 * @param data
 * @param columns
 */
export function downloadCsv(
  data: any[][],
  columns: Array<{ label: string; type?: string }>
): void {
  const csvContent = constructCsv(data, columns);
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'data.csv');
  document.body.appendChild(link); // Required for FF

  link.click();
}
