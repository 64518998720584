/**
 * Presentational component for a filter button.
 */

import IconButton from './IconButton';

export default function FilterButton({
  onClick,
}: {
  onClick: () => void;
}): JSX.Element {
  return (
    <IconButton
      onClick={onClick}
      icon={
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        >
        <line x1="3" y1="6" x2="21" y2="6" />
        <line x1="5" y1="12" x2="19" y2="12" />
        <line x1="7" y1="18" x2="17" y2="18" />
        </svg>
      }
    />
  );
}
