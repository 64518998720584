/**
 * Dropdown component for selecting multiple options.
 */

import { Menu, Transition } from '@headlessui/react';

export default function DropdownSelectMultiple({
  options,
  selected,
  handleChange,
  title,
  dataCy,
}: {
  options: Array<{ id: string; value: string }>;
  selected: Array<{ id: string; value: string }>;
  handleChange: (id: string) => void;
  title: string;
  dataCy?: string;
}): JSX.Element {
  return (
    <Menu as="div" className="relative inline-flex w-full" data-cy={dataCy}>
      {({ open }) => (
        <>
          <Menu.Button
            className="btn w-full justify-between min-w-[11rem] bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
            aria-label="Select option"
          >
            <span className="flex items-center">
              <span>{title}</span>
            </span>
            <svg
              className="shrink-0 ml-1 fill-current text-slate-400"
              width="11"
              height="7"
              viewBox="0 0 11 7"
            >
              <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
            </svg>
          </Menu.Button>
          <Transition
            className="z-10 absolute top-full left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 -translate-y-2"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Menu.Items className="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700 focus:outline-none">
              {options.map((option, optionIndex) => (
                <Menu.Item key={optionIndex}>
                  {({ active }) => (
                    <button
                      className={`flex items-center justify-between w-full py-2 px-3 cursor-pointer ${
                        active ? 'bg-slate-50 dark:bg-slate-700/20' : ''
                      } ${
                        selected.find((x) => option.id === x.id) !==
                          undefined && 'text-indigo-500'
                      }`}
                      onClick={() => {
                        handleChange(option.id);
                      }}
                    >
                      <span>{option.value}</span>
                    </button>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
