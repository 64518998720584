/**
 * Container component for the day parts table.
 */
import { useState, useEffect } from 'react';
import type { TableColumn } from '../../types/TableColumn';
import type { BestWorstValues } from '../../types/BestWorstValues';
import {
  sortDataByColumn,
  getMaxValueForColumn,
  getMinValueForColumn,
} from '../../utils/tables';
import type { DayPartData } from '../../types/DayPartData';
import { getDayPartsData } from '../../utils/api';
import { getAllDayPartsFromLocations } from '../../utils/locations';
import { useData } from '../../hooks/useData';
import TableLoader from './TableLoader';
import TableContainer from './TableContainer';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TableHeader from './TableHeader';
import Table from './Table';
import RefreshButton from '../buttons/RefreshButton';

interface RowType {
  locationName: string;
  [key: string]: string | number;
}

const buttonData = [
  {
    src: '/icons/walker.svg',
    alt: 'Customer Count',
    stat: 'customerCount',
  },
  {
    src: '/icons/clock.svg',
    alt: 'Average Service Time',
    stat: 'averageServiceTime',
  },
  {
    src: '/icons/bullseye.svg',
    alt: 'Deviation From Target',
    stat: 'deviationFromTarget',
  },
  {
    src: '/icons/uturn.svg',
    alt: 'Drive Off Count',
    stat: 'driveoffCount'
  }
];

const tableTitle = 'Day Parts';

export default function DayPartsTable(): JSX.Element {
  const {
    selectedFranchise,
    selectedDates,
    selectedLocations,
    toggled,
    userLocations,
  } = useData();
  const [dayPartsData, setDayPartsData] = useState<DayPartData[]>([]);
  const [displayData, setDisplayData] = useState<RowType[]>([]);
  const [rows, setRows] = useState<RowType[]>([]);
  const [columns, setColumns] = useState<TableColumn[]>([]);
  const [sortColumn, setSortColumn] = useState<TableColumn>({
    label: 'Location',
    type: 'string',
    key: 'locationName',
  });
  const [sortDirection, setSortDirection] = useState<number>(1);
  const [toggledStat, setToggledStat] = useState<string>('averageServiceTime');
  const [bestValues, setBestValues] = useState<BestWorstValues>({});
  const [worstValues, setWorstValues] = useState<BestWorstValues>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  async function fetchData(): Promise<void> {
    if (selectedFranchise === null) {
      return;
    }

    setIsLoading(true);

    const data = await getDayPartsData(
      selectedFranchise.id,
      selectedDates[0],
      selectedDates[0],
      userLocations
        .filter((location) => selectedLocations.includes(location))
        .map((location) => location.id),
      toggled === 'drive-thru'
    );
    setDayPartsData(data);

    setIsLoading(false);
  }

  useEffect(() => {
    fetchData().catch((err) => {
      console.error(err);
    });
  }, []);

  useEffect(() => {
    fetchData().catch((err) => {
      console.error(err);
    });
  }, [selectedDates[0], selectedFranchise, toggled]);

  useEffect(() => {
    const newData = getRows(dayPartsData);
    setRows(newData);
    const newColumns = getColumns(dayPartsData);
    setColumns(newColumns);
    const newBestValues: BestWorstValues = {};
    const newWorstValues: BestWorstValues = {};

    for (const col of newColumns) {
      if (col.type === 'string') continue;
      newBestValues[col.key] = getBestValue(newData, col);
      newWorstValues[col.key] = getWorstValue(newData, col);
    }

    setBestValues(newBestValues);
    setWorstValues(newWorstValues);
    handleSort(newData, newColumns[0], -1);
  }, [toggledStat, dayPartsData]);

  // Set the data to display based on the selected locations.
  useEffect(() => {
    const selected = selectedLocations.map((location) => location.displayName);
    setDisplayData(rows.filter((row) => selected.includes(row.locationName)));
  }, [rows, selectedLocations]);

  // Get the rows from the data.
  function getRows(rawData: DayPartData[]): RowType[] {
    const allDayParts = getAllDayPartsFromLocations(selectedLocations, toggled);
    if (rawData.length === 0) return [];
    const rows: RowType[] = [];
    for (const location of rawData) {
      const row: RowType = {
        locationName: location.locationName,
      };
      for (const dayPart of location.dayParts) {
        row[dayPart.name] = dayPart[toggledStat as keyof typeof dayPart];
      }
      for (const dayPart of Object.keys(allDayParts)) {
        if (row[dayPart] === undefined || row[dayPart] === null) {
          row[dayPart] = '--';
        }
      }
      rows.push(row);
    }
    return rows;
  }

  // Get the columns from the data.
  function getColumns(rawData: DayPartData[]): TableColumn[] {
    if (rawData.length === 0) return [];
    const allDayParts = getAllDayPartsFromLocations(selectedLocations, toggled);
    const cols = [
      {
        label: 'Location',
        type: 'string',
        key: 'locationName',
      },
    ];
    for (const dayPart of Object.values(allDayParts)) {
      cols.push({
        label: dayPart.name,
        type: ['customerCount', 'driveoffCount'].includes(toggledStat) ? 'number' : 'seconds',
        key: dayPart.name,
      });
    }
    return cols;
  }

  // Sort the data by the given column.
  function handleSort(
    rows: RowType[],
    column: TableColumn,
    sortDirection: number
  ): void {
    const sortedData = sortDataByColumn(rows, column, sortDirection);
    setRows(sortedData);
    setSortColumn(column);
    setSortDirection(sortDirection * -1);
  }

  // Get the best value for the given column.
  function getBestValue(data: RowType[], column: TableColumn): number {
    if (column.type === 'seconds') {
      return getMinValueForColumn(data, column);
    }
    return getMaxValueForColumn(data, column);
  }

  // Get the worst value for the given column.
  function getWorstValue(data: RowType[], column: TableColumn): number {
    if (column.type === 'seconds') {
      return getMaxValueForColumn(data, column);
    }
    return getMinValueForColumn(data, column);
  }
  if (selectedFranchise === null || isLoading) {
    return (
      <TableLoader
        columns={['Breakfast', 'Lunch', 'Dinner', 'After Dinner']}
        title={tableTitle}
        handleRefresh={() => {
          fetchData().catch((err) => {
            console.error(err);
          });
        }}
      />
    );
  }

  return (
    <TableContainer dataCy="day-parts-table">
      <TableHeader title={tableTitle}>
        <div className="flex items-center gap-12">
          <div className="flex items-center">
            {buttonData.map((button, index) => (
              <button
                key={index}
                onClick={() => {
                  setToggledStat(button.stat);
                }}
                className={`btn p-1 ${
                  toggledStat === button.stat
                    ? 'bg-white shadow-sm dark:bg-slate-700'
                    : 'bg-gray-100 dark:bg-slate-800'
                } focus:outline-none`}
                data-cy={`${button.stat}-toggle`}
              >
                <img src={button.src} alt={button.alt} />
              </button>
            ))}
          </div>
          <RefreshButton
            handleRefresh={() => {
              fetchData().catch((err) => {
                console.error(err);
              });
            }}
            dataCy="refresh-button-day-parts"
          />
        </div>
      </TableHeader>
      <div className="flex flex-wrap gap-4 p-4 bg-white dark:bg-[#182235]">
        <div className="btn text-sm text-white bg-green-500 p-0 px-2">Best</div>
        <div className="btn text-sm text-white bg-red-500 p-0 px-2">Worst</div>
      </div>
      <Table>
        <TableHead
          columns={columns}
          handleSort={(col) => {
            handleSort(rows, col, sortDirection);
          }}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
        <TableBody
          columns={columns}
          data={displayData}
          bestValues={bestValues}
          worstValues={worstValues}
        />
      </Table>
    </TableContainer>
  );
}
