/**
 * Presentational button component.
 */

export default function StandardButton({
  onClick,
  children,
  dataCy,
}: {
  onClick: () => void;
  children: React.ReactNode;
  dataCy?: string;
}): JSX.Element {
  return (
    <button
      className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm text-indigo-500"
      onClick={onClick}
      data-cy={dataCy}
    >
      {children}
    </button>
  );
}
