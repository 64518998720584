/**
 * A link that displays the departure time of a detection.
 */
import dateFormat from 'dateformat';

import type { Location } from '../../types/Location';
import { utcStringToLocalDate } from '../../utils/time';
import { useCamera } from '../../hooks/useCamera';

export default function ArrivalLocalTime({
  utcTime,
  location
}: {
  utcTime: string;
  location: Location;
  setDate?: (date: Date) => void;
  handleClick?: () => void;
  setIsLive?: (isLive: boolean) => void;
}): JSX.Element {
  const {
    setVideoDateTime
  } = useCamera();
  // Unpack the date.
  const localDate = utcStringToLocalDate(utcTime, location.timezone);
  const valueDate = new Date(localDate.valueOf());
  const formattedDate = dateFormat(localDate, 'mediumTime');
  return (
    <div
      className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setVideoDateTime(valueDate);
      }}
    >
      {formattedDate}
    </div>
  );
}
