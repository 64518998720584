/**
 * Container component for an individual location to update.
 */
import { useState } from 'react';
import type { Location } from '../../../types/Location';
import type { DayPart } from '../../../types/DayPart';
import { putLocationSettings } from '../../../utils/api';
import { useToast } from '../../../hooks/useToast';
import AccordionBasic from '../../accordions/AccordionBasic';
import ChangeDisplayNameSection from './sections/ChangeDisplayNameSection';
import UpdateDayPartsSection from './sections/UpdateDayPartsSection';

export default function LocationContainer({
  location,
}: {
  location: Location;
}): JSX.Element {
  const [displayName, setDisplayName] = useState(location.displayName);
  const [newDayParts, setNewDayParts] = useState<DayPart[]>(
    location.dtConfiguration?.dayParts ?? []
  );
  const { setToast } = useToast();

  // Handle changing the display name input.
  function handleDisplayNameInputChange(
    e: React.ChangeEvent<HTMLInputElement>
  ): void {
    setDisplayName(e.target.value);
  }

  // Handle updating the new day parts.
  function handleDayPartsInputChange(
    e: React.ChangeEvent<HTMLInputElement>
  ): void {
    const { name, value } = e.target;
    const dayPartName = name.split('-')[0];
    const dayPartProp = name.split('-')[1];
    const dayPartIndex = newDayParts.findIndex(
      (dayPart) => dayPart.name === dayPartName
    );
    const newDayPartsCopy: any[] = [...newDayParts];
    newDayPartsCopy[dayPartIndex][dayPartProp as keyof DayPart] = value;
    setNewDayParts(newDayPartsCopy);
  }

  // Handle updating the time for a day part.
  function handleUpdateDayPartTime(
    name: string,
    dateStr: string,
    isFrom: boolean
  ): void {
    const dayPartIndex = newDayParts.findIndex(
      (dayPart) => dayPart.name === name
    );
    const newDayPartsCopy: any[] = [...newDayParts];
    if (isFrom) {
      newDayPartsCopy[dayPartIndex].from = dateStr;
    } else {
      newDayPartsCopy[dayPartIndex].to = dateStr;
    }
    setNewDayParts(newDayPartsCopy);
  }

  // Handle adding a new day part.
  function handleAddDayPart(): void {
    setNewDayParts([
      ...newDayParts,
      {
        name: 'New Day Part',
        from: '',
        to: '',
        targetTime: 0,
        percentage: 0,
      },
    ]);
  }

  // Handle deleting a day part.
  function handleDeleteDayPart(name: string): void {
    setNewDayParts(newDayParts.filter((dayPart) => dayPart.name !== name));
  }

  async function handleSaveLocations(): Promise<void> {
    const res = await putLocationSettings(
      location.franchiseId,
      location.id,
      displayName,
      newDayParts
    );
    if (res) {
      setToast({
        show: true,
        message: 'Location settings updated',
        type: 'success',
      });
    } else {
      setToast({
        show: true,
        message: 'Error updating location settings',
        type: 'error',
      });
    }
  }

  return (
    <AccordionBasic title={location.displayName} dataCy="location-accordion">
      <div className="flex flex-col gap-4">
        {/* Change Display Name */}
        <ChangeDisplayNameSection
          displayName={displayName}
          handleInputChange={handleDisplayNameInputChange}
        />
        {/* Update Day Parts */}
        <UpdateDayPartsSection
          dayParts={newDayParts}
          handleInputChange={handleDayPartsInputChange}
          handleTimeChange={handleUpdateDayPartTime}
          handleAdd={handleAddDayPart}
          handleDelete={handleDeleteDayPart}
          handleSave={() => {
            handleSaveLocations().catch((error) => {
              console.error('Error saving location settings', error);
              setToast({
                show: true,
                message: 'Error saving location settings',
                type: 'error',
              });
            });
          }}
        />
      </div>
    </AccordionBasic>
  );
}
