/**
 * The head displaying the columns of the table.
 */
import type { TableColumn } from '../../types/TableColumn';

export default function TableHead({
  columns,
  handleSort,
  sortColumn,
  sortDirection,
}: {
  columns: TableColumn[];
  handleSort: (col: TableColumn) => void;
  sortColumn: TableColumn;
  sortDirection: number;
}): JSX.Element {
  return (
    <thead className="text-xs uppercase text-gray-400 rounded-sm bg-gray-50 dark:bg-[#182235] border-slate-200 dark:border-slate-700">
      <tr>
        {columns.map((col) => (
          <th
            className="p-2 w-24 sticky top-0 bg-gray-50 dark:bg-[#182235] dark:text-white"
            key={`header-${col.key}`}
            onClick={() => {
              handleSort(col);
            }}
            data-cy="column-name"
          >
            <div className="text-left cursor-pointer select-none">
              <span>{col.label}</span>
              {col.key === sortColumn.key && (
                <span className="ml-1">
                  {sortDirection === -1 ? <>&#9650; </> : <>&#9660;</>}
                </span>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
}
