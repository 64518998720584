"use client"

import { ColumnDef } from "@tanstack/react-table"
import { ArrowUpDown } from "lucide-react"
import { Button } from "../ui/button"
import ArrivalTimeLinkLocal from "../ui/ArrivalTimeLinkLocal"
import { Location } from "@/types/Location"


// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type DriveOff = {

    timeStamp: string
    location: Location
  }

export const columns: ColumnDef<DriveOff>[] = [
  {
    accessorKey: "timeStamp",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          TimeStamp
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      
      const time = row.getValue("timeStamp") as string
      const location = row.getValue("location") as Location
 
      return <ArrivalTimeLinkLocal utcTime={time} location={location}/>
    },
    
  },
  {
    accessorKey: "location",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Location Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      
      const location = row.getValue("location") as Location
 
      return <div>{location.displayName}</div>
    },
    filterFn: (row, columnId, filterValue) => {
      const locationName = row.original.location.displayName || '';
      return locationName.toLowerCase().includes(filterValue.toLowerCase());
    },
    sortingFn: (rowA, rowB, columnId) => {
      const locationNameA = rowA.original.location.displayName || '';
      const locationNameB = rowB.original.location.displayName || '';
      
      return locationNameA.localeCompare(locationNameB);
    },
  }
]
