/**
 * Dropdown for selecting multiple locations.
 */
import { useData } from '../../hooks/useData';
import type { Location } from '../../types/Location';

import DropdownFilter from './DropdownFilter';

export default function DropdownLocationMultiple({
  dataCy = 'dropdown-location-multiple',
}: {
  dataCy?: string;
}): JSX.Element {
  const {
    displayedLocations,
    selectedLocations,
    handleSelectedLocationsChange,
    handleFilter,
    handleSelectAll,
    handleDeselectAll,
  } = useData();

  return (
    <DropdownFilter
      title={`${selectedLocations.length} Locations`}
      options={displayedLocations.map((location: Location) => ({
        id: location.id,
        value: location.displayName,
      }))}
      selected={selectedLocations.map((location) => ({
        id: location.id,
        value: location.displayName,
      }))}
      handleChange={handleSelectedLocationsChange}
      filterFunction={handleFilter}
      selectAllFunction={handleSelectAll}
      deselectAllFunction={handleDeselectAll}
      dataCy={dataCy}
    />
  );
}
