import { createContext, useContext, useState } from 'react';
import type { UserProfile } from '../types/UserProfile';

interface UseProfile {
  profile: UserProfile | null;
  setProfile: (profile: UserProfile) => void;
}

const dummyContext: UseProfile = {
  profile: null,
  setProfile: (profile: UserProfile) => {},
};

const profileContext = createContext(dummyContext);

export const ProvideProfile: React.FC = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const profile = useProvideProfile();
  return (
    <profileContext.Provider value={profile}>
      {children}
    </profileContext.Provider>
  );
};

export const useProfile = (): UseProfile => {
  return useContext(profileContext);
};

const useProvideProfile = (): UseProfile => {
  const [profile, setProfile] = useState<UserProfile | null>(null);

  return { profile, setProfile };
};
