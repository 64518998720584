/**
 * React hook for displaying a loading spinner while data is being loaded.
 */

import { createContext, useContext, useState } from 'react';

// Interface for the load context.
interface LoadContext {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const dummyContext: LoadContext = {
  isLoading: false,
  setIsLoading: (isLoading: boolean) => {},
};

// Create the load context.
const loadContext = createContext(dummyContext);

// Create the load provider.
export const ProvideLoad: React.FC = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const load = useProvideLoad();

  return <loadContext.Provider value={load}>{children}</loadContext.Provider>;
};

// Create the useLoad hook.
export const useLoad = (): LoadContext => {
  return useContext(loadContext);
};

// Create the useProvideLoad hook.
const useProvideLoad = (): LoadContext => {
  const [isLoading, setIsLoading] = useState(false);

  return { isLoading, setIsLoading };
};
