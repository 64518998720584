/**
 * Container component for changing password.
 */
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';
import AuthContainer from '../../../components/auth/AuthContainer';
import AuthImage from '../../../components/auth/AuthImage';

export default function ChangePassword(): JSX.Element {
  const [data, setData] = useState({
    code: '',
    newPassword: '',
  });
  const { setToast } = useToast();

  const [searchParams] = useSearchParams();
  const { resetPasswordSubmit } = useAuth();

  const email = searchParams.get('email');

  if (email === null) {
    window.location.replace('/forgot-password');
  }

  // Handle input change
  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  // Handle change password
  async function handleChangePassword(): Promise<void> {
    if (data.code === '' || data.newPassword === '') {
      setToast({
        show: true,
        message: 'Please fill in all fields',
        type: 'error',
      });
      return;
    }

    if (email === null) {
      return;
    }

    try {
      await resetPasswordSubmit(email, data.code, data.newPassword);
      window.location.replace('/signin');
    } catch (error: any) {
      console.log('error', error);
      setToast({
        show: true,
        message: error.message,
        type: 'error',
      });
    }
  }

  return (
    <main className="bg-white dark:bg-slate-900">
      <AuthContainer>
        <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">
          Change your Password ✨
        </h1>
        {/* Form */}
        <div>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="code">
                Verification Code <span className="text-rose-500">*</span>
              </label>
              <input
                id="code"
                name="code"
                className="form-input w-full"
                type="text"
                data-cy="code-input"
                onChange={handleChange}
              />
            </div>
          </div>
          <div>
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="password"
            >
              New Password <span className="text-rose-500">*</span>
            </label>
            <input
              id="newPassword"
              name="newPassword"
              className="form-input w-full"
              type="password"
              data-cy="new-password-input"
              onChange={handleChange}
            />
          </div>
          <div className="flex justify-end mt-6">
            <button
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap"
              onClick={() => {
                handleChangePassword().catch((err) => {
                  console.log(err);
                });
              }}
            >
              Change Password
            </button>
          </div>
          <div>
            <p className="text-sm text-slate-800 dark:text-slate-100 mt-4">
              <span className="font-semibold">Note:</span> Your password must be
              at least 8 characters long and contain at least one uppercase, one
              lowercase, one number, and one special character.
            </p>
          </div>
        </div>
      </AuthContainer>

      {/* Image */}
      <AuthImage />
    </main>
  );
}
