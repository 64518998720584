/**
 * Dropdown for selecting a single location.
 */
import { useData } from '../../hooks/useData';
import type { Location } from '../../types/Location';

import DropdownFilter from './DropdownFilter';

export default function DropdownLocationSingle(): JSX.Element {
  const {
    userLocations,
    displayedLocations,
    selectedLocation,
    setSelectedLocation,
    handleFilter,
  } = useData();

  if (selectedLocation === null || selectedLocation === undefined) {
    return <></>;
  }

  return (
    <DropdownFilter
      title={selectedLocation.displayName}
      options={displayedLocations.map((location: Location) => ({
        id: location.id,
        value: location.displayName,
      }))}
      selected={[
        {
          id: selectedLocation.id,
          value: selectedLocation.displayName,
        },
      ]}
      handleChange={(id: string) => {
        const location = userLocations.find(
          (location: Location) => location.id === id
        );
        if (location === undefined) {
          return;
        }
        setSelectedLocation(location);
      }}
      filterFunction={handleFilter}
      dataCy="dropdown-location-single"
    />
  );
}
