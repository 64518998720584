/**
 * Container component for the stations table.
 */
import { useEffect, useState } from 'react';
import type { TableColumn } from '../../types/TableColumn';
import { getRegionOrder, sortDataByColumn } from '../../utils/tables';
import type { RegionsStats } from '../../types/RegionsStats';
import { getRegionsStats } from '../../utils/api';
import { useData } from '../../hooks/useData';
import TableLoader from './TableLoader';
import TableContainer from './TableContainer';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TableHeader from './TableHeader';
import Table from './Table';
import RefreshButton from '../buttons/RefreshButton';

interface RowType {
  locationName: string;
  [key: string]: string | number;
}

export default function StationsTable(): JSX.Element {
  const {
    selectedFranchise,
    selectedDates,
    selectedLocations,
    toggled,
    userLocations,
  } = useData();
  const [data, setData] = useState<RowType[]>([]);
  const [displayData, setDisplayData] = useState<RowType[]>([]);
  const [columns, setColumns] = useState<TableColumn[]>([]);
  const [sortColumn, setSortColumn] = useState<TableColumn>({
    label: 'Location',
    type: 'string',
    key: 'locationName',
  });
  const [sortDirection, setSortDirection] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Fetch the data.
  async function fetchData(): Promise<void> {
    if (selectedFranchise === null) {
      return;
    }
    setIsLoading(true);

    const regionEndDate = new Date(selectedDates[0]);
    regionEndDate.setDate(regionEndDate.getDate() + 1);

    const data = await getRegionsStats(
      selectedFranchise.id,
      selectedDates[0],
      regionEndDate,
      userLocations
        .filter((location) => selectedLocations.includes(location))
        .map((location) => location.id),
      toggled === 'drive-thru'
    );
    setColumns(getColumns(data));
    handleSort(getRows(data), sortColumn, sortDirection);
    setIsLoading(false);
  }

  // Fetch the data on load.
  useEffect(() => {
    fetchData().catch((err) => {
      console.error(err);
    });
  }, []);

  // Fetch the data when the selected date changes.
  useEffect(() => {
    fetchData().catch((err) => {
      console.error(err);
    });
  }, [selectedDates[0], selectedFranchise, toggled]);

  // Set the data to display based on the selected locations.
  useEffect(() => {
    const selected = selectedLocations.map((location) => location.displayName);
    setDisplayData(data.filter((row) => selected.includes(row.locationName)));
  }, [data, selectedLocations]);

  // Get the rows from the data.
  function getRows(rawData: RegionsStats[]): RowType[] {
    if (rawData.length === 0) return [];
    const rows = [] as RowType[];
    for (const location of rawData) {
      const row: RowType = {
        locationName: location.locationName,
      };
      for (const region of location.regions) {
        if (region.timeSpent === undefined) {
          region.timeSpent = 0;
        }
        row[region.key] = region.timeSpent;
      }
      rows.push(row);
    }
    return rows;
  }

  // Get the columns from the data.
  function getColumns(rawData: RegionsStats[]): TableColumn[] {
    if (rawData.length === 0) return [];
    const regions: Record<string, string> = {};
    let cols = [];
    // Get all possible regions.
    for (const data of rawData) {
      for (const region of data.regions) {
        if (region.timeSpent) {
          regions[region.key] = region.displayName;
        }
      }
    }

    for (const key in regions) {
      cols.push({
        label: regions[key],
        type: 'seconds',
        key,
      });
    }

    // sort columns
    cols = getRegionOrder(cols);

    // Set the location column as the first.
    cols = [
      {
        label: 'Location',
        type: 'string',
        key: 'locationName',
      },
      ...cols,
    ];

    return cols;
  }

  // Sort the data by the given column.
  function handleSort(
    data: RowType[],
    column: TableColumn,
    sortDirection: number
  ): void {
    const sortedData = sortDataByColumn(data, column, sortDirection);
    setData(sortedData);
    setSortColumn(column);
    setSortDirection(sortDirection * -1);
  }

  // Handle refreshing the data.
  async function handleRefresh(): Promise<void> {
    await fetchData();
  }
  const tableTitle = 'Stations';

  if (selectedFranchise === null || isLoading) {
    return (
      <TableLoader
        columns={['Location', 'Order', 'Payment']}
        title={tableTitle}
        handleRefresh={() => {
          handleRefresh().catch((err) => {
            console.error(err);
          });
        }}
      />
    );
  }

  return (
    <TableContainer dataCy="stations-table">
      <TableHeader title={tableTitle}>
        <RefreshButton
          handleRefresh={() => {
            handleRefresh().catch((err) => {
              console.error(err);
            });
          }}
          dataCy="refresh-button-regions"
        />
      </TableHeader>
      <Table>
        <TableHead
          columns={columns}
          handleSort={(col) => {
            handleSort(data, col, sortDirection);
          }}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
        <TableBody columns={columns} data={displayData} />
      </Table>
    </TableContainer>
  );
}
