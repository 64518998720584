/**
 * This page is for users that have a status of NEW_PASSWORD_REQUIRED.
 */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import AuthImage from '../../components/auth/AuthImage';
import AuthContainer from '../../components/auth/AuthContainer';

function ChangePasswordPage(): JSX.Element {
  const { user, completeNewPassword } = useAuth();
  const [data, setData] = useState({
    newPassword: '',
    confirmNewPassword: '',
  });
  const navigate = useNavigate();
  const { setToast } = useToast();

  // Ensure the user is signed in and has a status of NEW_PASSWORD_REQUIRED.
  useEffect(() => {
    if (user === null) {
      navigate('/signin');
    }
    if (user?.challengeName !== 'NEW_PASSWORD_REQUIRED') {
      navigate('/');
    }
  }, []);

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  async function handleChangePassword(): Promise<void> {
    if (data.newPassword === '' || data.confirmNewPassword === '') {
      setToast({
        show: true,
        message: 'Please fill in all fields',
        type: 'error',
      });
      return;
    }

    if (data.newPassword !== data.confirmNewPassword) {
      setToast({
        show: true,
        message: 'Passwords do not match',
        type: 'error',
      });
      return;
    }

    await completeNewPassword(data.newPassword);
    window.location.replace('/');
  }

  return (
    <main className="bg-white dark:bg-slate-900">
      <AuthContainer>
        <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">
          Change Your Password ✨
        </h1>
        {/* Form */}

        <div className="space-y-4">
          <div>
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="newPassword"
            >
              New Password <span className="text-rose-500">*</span>
            </label>
            <input
              id="newPassword"
              name="newPassword"
              className="form-input w-full"
              type="password"
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="confirmNewPassword"
            >
              Confirm New Password <span className="text-rose-500">*</span>
            </label>
            <input
              id="confirmNewPassword"
              name="confirmNewPassword"
              className="form-input w-full"
              type="password"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <button
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap"
            onClick={() => {
              handleChangePassword().catch((err) => {
                console.log(err);
              });
            }}
          >
            Set Password
          </button>
        </div>
        <div>
          <p className="text-sm text-slate-800 dark:text-slate-100 mt-4">
            <span className="font-semibold">Note:</span> Your password must be
            at least 8 characters long and contain at least one uppercase, one
            lowercase, one number, and one special character.
          </p>
        </div>
      </AuthContainer>
      {/* Image */}
      <AuthImage />
    </main>
  );
}

export default ChangePasswordPage;
