
import { useEffect } from "react";
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';


import { ReservedKeyword } from "../../interfaces/reservedKeyword";
import DropdownFranchise from "../../components/dropdowns/DropdownFranchise";
import DropdownLocationMultiple from "../../components/dropdowns/DropdownLocationMultiple";
import { useData } from "../../hooks/useData";
import VideoPlayerKinesis from '../../components/cameras/VideoPlayerKinesis';
import LiveButton from '../../components/cameras/LiveButton';


import {
useCamera,
} from '../../hooks/useCamera';


const MonitorViewer = () => {

const {
selectedCamera,
setSelectedCamera,
visibleCameras,
setVisibleCameras,
numCamerasDisplayed,
setNumCamerasDisplayed,
setVideoDateTime,
setIsLive,
currentPage,
setTotalPages,
isDownloading,
setIsDownloading,
isGrid,
kinesisVideo,
setKinesisVideo,
availableCameras,
setAvailableCameras,
showCvToggle,
setShowCvToggle,
showCvStream,
setShowCvStream,
showCvDtStream,
setShowCvDtStream,
showMonitorStream,
setShowMonitorStream,
hasCvStream,
setHasCvStream,
hasCvDtStream,
setHasCvDtStream,
hasMonitorStream,
setHasMonitorStream,
setIsEditing,
cameraOrder,
setCameraOrder,
} = useCamera();

const reservedKeywords: ReservedKeyword = {
"blackout": {
polygonColor: "rgba(0,0,0,1)",
description: "Reserved keyword that blacks out the polygon. Color cannot be modified"
},
"ginger": {
polygonColor: "rgba(235, 47, 6, 1.0)",
description: "Reserved keyword that sets the color to an intense tomato red. Color cannot be modified"
}
};

const {
selectedFranchise,
selectedLocations
} = useData();


// Get AWS credentials on load.
useEffect(() => {
async function setUpKinesis(): Promise<void> {
const userCredentials = await Auth.currentCredentials();
AWS.config.update({
region: import.meta.env.VITE_AWS_REGION,
credentials: Auth.essentialCredentials(userCredentials),
});
setKinesisVideo(new AWS.KinesisVideo());
}
setUpKinesis().catch((error) => {
console.error('Error setting up Kinesis', error);
});
}, []);






return (
// <h1>monitors go here</h1>
<div className="cont">
<div className="row">
<div className="col-10 col-m-10 p-2 mx-auto">
</div>
<div className="flex flex-wrap justify-start gap-2 col-10 col-m-10 p-2 mx-auto">
{/* Franchise Selector */}
<DropdownFranchise />
{/* Location Selector */}
<DropdownLocationMultiple />
<LiveButton />
{/* New Button to refresh monitors */}

</div>
</div> 
{/* Video Players Grid */}
<div
className={
!isGrid
? 'grid grid-cols-1 gap-4'
: 'grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4'
}
>
{/* Video player for monitors on Kinesis. */}
{selectedLocations !== null &&
selectedLocations.map(
(location) =>
<VideoPlayerKinesis
key={location.id}
location={location}
camera="MONITOR"
/>
)}
</div>
</div>
);
};

export default MonitorViewer;
