/**
 * Utility functions for working with PoS data.
 */
import type { Order } from '../types/Order';

/**
 * @description Calculate the total sales for each day part.
 * @param orders The orders to calculate the totals for.
 * @param date The date to calculate the totals for.
 * @returns An object containing the total sales for each day part.
 */
export function calculateHourlyTotal(
  orders: Order[],
  date: Date
): Record<string, number> {
  const hourlyTotals: Record<string, number> = {};

  // Initialize the hourly totals object
  for (let i = 0; i < 24; i++) {
    hourlyTotals[
      String(i).padStart(2, '0') +
        ':00-' +
        String((i + 1) % 24).padStart(2, '0') +
        ':00'
    ] = 0;
  }

  orders.forEach((order) => {
    const orderDate = new Date(order.checkoutTime);

    // Check if the order date matches the input date
    if (
      orderDate.getFullYear() === date.getFullYear() &&
      orderDate.getMonth() === date.getMonth() &&
      orderDate.getDate() === date.getDate()
    ) {
      hourlyTotals[getHourKey(orderDate)] += order.total;
    }
  });

  return hourlyTotals;
}

/**
 * @description Get the hour key for a given date.
 * @param date The date to get the hour key for.
 * @returns The hour key for the given date.
 */
export function getHourKey(date: Date): string {
  return (
    String(date.getHours()).padStart(2, '0') +
    ':00-' +
    String((date.getHours() + 1) % 24).padStart(2, '0') +
    ':00'
  );
}
