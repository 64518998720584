/**
 * Dropdown selector for franchises.
 */

import { useData } from '../../hooks/useData';

import DropdownFull from '../dropdowns/DropdownFull';

export default function DropdownFranchise(): JSX.Element {
  const { userFranchises, selectedFranchise, handleFranchiseChange } =
    useData();

  if (selectedFranchise === null) {
    return <></>;
  }

  return (
    <DropdownFull
      options={userFranchises.map((franchise) => ({
        id: franchise.id,
        value: franchise.displayName,
      }))}
      selected={{
        id: selectedFranchise.id,
        value: selectedFranchise.displayName,
      }}
      handleChange={handleFranchiseChange}
      dataCy="dropdown-franchise"
    />
  );
}
