// src/components/UserItem.tsx
import React, { useState } from 'react';
import { Roles } from '../../../types/Roles';
import { FranchiseUser } from '../../../types/FranchiseUser';
import AccordionBasic from '../../accordions/AccordionBasic';
import DropdownFilter from '../../dropdowns/DropdownFilter';
import { FranchiseLocation } from '../../../types/FranchiseLocation';
import ModalBasic from '../../modals/ModalBasic';
import { useAuth } from '../../../hooks/useAuth';

interface UserItemProps {
  currentUser: FranchiseUser;
  franchiseLocations: Array<FranchiseLocation>;
  onSave: (user: FranchiseUser) => void;
  onDelete: (user: FranchiseUser) => void;
}

const UserItem: React.FC<UserItemProps> = ({ currentUser, franchiseLocations, onSave, onDelete }) => {
  const [editUser, setEditUser] = useState<FranchiseUser>({ ...currentUser });
  const [isOpen, setIsOpen] = useState(false);
  const userRoles = [{id: Roles.ADMIN, value: Roles.ADMIN}, {id: Roles.MANAGER, value: Roles.MANAGER}, {id: Roles.MEMBER, value: Roles.MEMBER}];
  const { user } = useAuth();


  const handleSave = () => {
    onSave(editUser);
  };

  const handleDiscardChanges = () => {
    setEditUser(currentUser);
  }

  const handleDelete = () => {
    onDelete(editUser);
  }

  function handleSelectedLocationsChange(id: string): void {
    let newSelectedLocations;

    const locationSelected = editUser.locationIds.find((locId) => locId === id);

    if (locationSelected !== undefined) {
      newSelectedLocations = editUser.locationIds.filter((locId) => locId !== id);
      setEditUser({...editUser, locationIds: newSelectedLocations});
    } else {
      const location = franchiseLocations.find((loc) => loc.locationId === id);
      newSelectedLocations = [...editUser.locationIds, location?.locationId!];
      setEditUser({...editUser, locationIds: newSelectedLocations});
    }
  }

  const isSaveButtonEnabled = editUser.locationIds.length > 0;

  return (
    <AccordionBasic title={editUser.email} dataCy="location-accordion">
      <div className="flex flex-wrap w-full gap-4">
        {/* Change Role */}
        <div className="flex flex-col">
            <label>Role</label>
            <DropdownFilter
                title={editUser.userRole}
                options={userRoles}
                selected={[{id: editUser.userRole, value: editUser.userRole}]}
                handleChange={(id) => setEditUser({ ...editUser, userRole: userRoles.find((val) => val.id == id)?.value!})}
                dataCy={'dropdown-region-multiple'}
            />
        </div>
        {/* Change Locations */}
        <div className="flex flex-col">
            <label>Locations</label>
            <DropdownFilter
                title={`${franchiseLocations.filter((loc) => editUser.locationIds.includes(loc.locationId)).length} Locations`}
                options={franchiseLocations.map((loc) => { return {id: loc.locationId, value: loc.displayName}})}
                selected={franchiseLocations.filter((loc) => editUser.locationIds.includes(loc.locationId)).map((loc) => { return {id: loc.locationId, value: loc.displayName}})}
                handleChange={handleSelectedLocationsChange}
                dataCy={'dropdown-region-multiple'}
            />
        </div>
        {/* Save Button */}
        <div className="flex flex-col">
            <label>Save</label>
            <button
                className={`btn ${isSaveButtonEnabled ? 'border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm text-indigo-500' : 'text-gray-500 border-gray-200 cursor-not-allowed'}`}
                onClick={handleSave}
                disabled={!isSaveButtonEnabled}
                data-cy="save-user-button"
            >
                Save
            </button>
        </div>
        {/* Save Button */}
        <div className="flex flex-col">
            <label>Discard Changes</label>
            <button
                className="btn bg-orange-500 hover:bg-orange-600 text-white"
                onClick={handleDiscardChanges}
                data-cy="save-user-button"
            >
                Discard Changes
            </button>
        </div>
        {/* Delete Button */}
        {user?.attributes.email != currentUser.email && 
          <div className="flex flex-col">
            <label>Delete</label>
            <button
                className="btn bg-rose-500 hover:bg-rose-600 text-white"
                onClick={() => setIsOpen(true)}
                data-cy="delete-user-button"
            >
                Delete
            </button>
        </div>
        }
        <div hidden={true}>
          <ModalBasic
            title="Delete User"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            dataCy="modal-delete-user"
          >
              <div className="flex flex-col gap-4 min-w-96 p-4">
                  {/* Change Email */}
                      <h3>Are you sure you want to delete {editUser.email}?</h3>
                  {/* Delete Button */}
                  <div className="flex flex-rowƒsp justify-end">
                    <div className='pr-1'>
                      <button
                        className="btn bg-rose-500 hover:bg-rose-600 text-white"
                        onClick={handleDelete}
                        data-cy="save-user-button"
                      >
                        Yes
                      </button>
                    </div>
                    <div className='pl-1'>
                      <button
                        className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm text-indigo-500"
                        onClick={() => setIsOpen(false)}
                        data-cy="delete-user-button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
              </div>
          </ModalBasic>
        </div>
      </div>
    </AccordionBasic>
  );
};

export default UserItem;
