/**
 * Presentational section for setting a user's default location.
 */
import DropdownFull from '../../../dropdowns/DropdownFull';
import type { Location } from '../../../../types/Location';
import type { UserProfile } from '../../../../types/UserProfile';
import SectionTitle from './SectionTitle';

export default function DefaultLocationSection({
  userProfile,
  selectedLocation,
  handleChange,
}: {
  userProfile: UserProfile;
  selectedLocation: Location;
  handleChange: (id: string) => void;
}): JSX.Element {
  const options = userProfile.locations.map((location) => ({
    id: location.id,
    value: location.displayName,
  }));

  const selected = {
    id: selectedLocation.id,
    value: selectedLocation.displayName,
  };

  return (
    <section>
      <SectionTitle title="Set Default Location" />
      <p className="text-slate-600 dark:text-slate-400 mb-4">
        This is the location that will be your default on the cameras page.
      </p>
      <DropdownFull
        options={options}
        selected={selected}
        handleChange={handleChange}
        dataCy="default-location-dropdown"
      />
    </section>
  );
}
