import { Labelme } from "../../interfaces/labelme";
import JSONViewer from "../JSONViewer/jsonViewer";
import '../../css/labelme.css';


export interface ExporterProps {
    dataToExport: Labelme | undefined;
    fileName: string;
}

const Exporter = (props: ExporterProps) => {
    return (
        <div className="w-100">
            <h1>MAKE SURE THIS IS THE CORRECT FILENAME: {props.fileName}</h1>
            <div className="row mb-4">
                <div className="col-12">
                    <h2 className="color-white">Data to export</h2>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12">
                <p className="color-white">Click export data to generate the data</p>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12">
                    <p className="color-white" style={{ fontSize: '24px', fontWeight: 'bold' }}>
                        MAKE SURE THIS IS THE CORRECT FILENAME: {props.fileName}.json
                    </p>
                </div>
            </div>

            {
                props.dataToExport
                ? (
                    <div className="row">
                        <div className="col-12">
                            <JSONViewer 
                                object = { props.dataToExport } 
                                fileName={props.fileName}
                            />
                        </div>
                    </div>
                )
                : <></>
            }
           
        </div>
    )
};

export default Exporter;
