"use client"

import { ColumnDef } from "@tanstack/react-table"
import { ArrowUpDown } from "lucide-react"
import { Button } from "../ui/button"
import ArrivalTimeLinkLocal from "../ui/ArrivalTimeLinkLocal"
import { Location } from "@/types/Location"


// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type Stack = {

    timeStamp: string
    location: Location
    stackSize: number
    duration: number
  }

function formatSecondsToHHMMSS(totalSeconds: number) {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);
  const milliseconds = Math.floor((totalSeconds % 1) * 100); // Extract milliseconds

  // Build the formatted time conditionally
  let formattedTime = '';

  // Only add hours if they are greater than 0
  if (hours > 0) {
    formattedTime += `${String(hours)}h`;
  }
  // Always add seconds
  formattedTime += ` ${String(minutes)}m ${String(seconds).padStart(2, '0')}.${String(milliseconds).padStart(2, '0')}s`;

  return formattedTime;
};

export const columns: ColumnDef<Stack>[] = [
  {
    accessorKey: "timeStamp",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          TimeStamp
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      
      const time = row.getValue("timeStamp") as string
      const location = row.getValue("location") as Location
 
      return <ArrivalTimeLinkLocal utcTime={time} location={location}/>
    },
    
  },
  {
    accessorKey: "location",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Location Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      
      const location = row.getValue("location") as Location
 
      return <div>{location.displayName}</div>
    },
    filterFn: (row, columnId, filterValue) => {
      const locationName = row.original.location.displayName || '';
      return locationName.toLowerCase().includes(filterValue.toLowerCase());
    },
    sortingFn: (rowA, rowB, columnId) => {
      const locationNameA = rowA.original.location.displayName || '';
      const locationNameB = rowB.original.location.displayName || '';
      
      return locationNameA.localeCompare(locationNameB);
    },
  },
  {
    accessorKey: "stackSize",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Stack Size
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      )
    }
  },
  // {
  //   accessorKey: "duration",
  //   header: ({ column }) => {
  //     return (
  //       <Button
  //         variant="ghost"
  //         onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
  //       >
  //         Stack Duration
  //         <ArrowUpDown className="ml-2 h-4 w-4" />
  //       </Button>
  //     )
  //   },
  //   cell: ({ row }) => {
      
  //     const location = row.getValue("duration") as number
 
  //     return <div>{formatSecondsToHHMMSS(location)}</div>
  //   },
  // }
]
