/**
 * Presentational component for selecting the camera page.
 */

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useCamera } from '../../hooks/useCamera';

export default function PageSelector(): JSX.Element {
  const { currentPage, setCurrentPage, totalPages } = useCamera();

  return (
    <div className="flex justify-center">
      <div className="m-3 flex flex-row rounded-lg p-2">
        <div className="inline-flex">
          <button
            className="btn bg-white dark:bg-slate-800 rounded-tr-none rounded-br-none focus:outline-none"
            onClick={() => {
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
              }
            }}
            data-cy="previous-page-button"
          >
            <NavigateBeforeIcon />
          </button>
          <div className="btn bg-white dark:bg-slate-800 rounded-none focus:outline-none cursor:default">
            <span data-cy="current-page">{currentPage}</span>
            <span className="mx-1"> of </span>
            <span data-cy="total-pages">{totalPages}</span>
          </div>
          <button
            className="btn bg-white dark:bg-slate-800 rounded-bl-none rounded-tl-none focus:outline-none"
            onClick={() => {
              if (currentPage < totalPages) {
                setCurrentPage(currentPage + 1);
              }
            }}
            data-cy="next-page-button"
          >
            <NavigateNextIcon />
          </button>
        </div>
      </div>
    </div>
  );
}
