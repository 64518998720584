/**
 * Container component for the Orders table.
 */
import { useEffect, useState } from 'react';
import { useData } from '../../hooks/useData';
import { sortDataByColumn } from '../../utils/tables';
import type { TableColumn } from '../../types/TableColumn';
import type { Order } from '../../types/Order';
import TableLoader from './TableLoader';
import TableContainer from './TableContainer';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TableHeader from './TableHeader';
import Table from './Table';
import RefreshButton from '../buttons/RefreshButton';
import FilterButton from '../buttons/FilterButton';
import ModalBasic from '../modals/ModalBasic';
import FilterModal from '../modals/FilterModal';

type DataType = Order[];

export default function OrdersTable({
  orders,
  fullOrders,
  handleRefresh,
  setAppliedFilters,
  selectedFilters,
  setSelectedFilters,
  isLoading,
}: {
  orders: Order[];
  fullOrders: Order[];
  handleRefresh: () => void;
  setAppliedFilters: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;  
  selectedFilters: Record<string, string[]>;
  setSelectedFilters: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
  isLoading: boolean;
}): JSX.Element {
  const { selectedLocation } = useData();
  const columns = [
    {
      label: 'Checkout',
      key: 'checkoutTime',
      type: 'date',
    },
    {
      label: 'Where',
      key: 'location',
      type: 'string',
    },
    {
      label: 'Order No.',
      key: 'orderNumber',
      type: 'number',
    },
    {
      label: 'Cashier',
      key: 'cashier',
      type: 'string',
    },
    {
      label: 'Payment Type',
      key: 'paymentType',
      type: 'string',
    },
    {
      label: 'Sub Total',
      key: 'subtotal',
      type: 'USD',
    },
    {
      label: 'Tax',
      key: 'tax',
      type: 'USD',
    },
    {
      label: 'Tender',
      key: 'tender',
      type: 'USD',
    },
    {
      label: 'Change',
      key: 'change',
      type: 'USD',
    },
    {
      label: 'Total',
      key: 'total',
      type: 'USD',
    },
  ];
  const columnsWithFilters = ['location', 'cashier', 'paymentType'];
  const [data, setData] = useState<DataType>(orders);
  const [sortColumn, setSortColumn] = useState<TableColumn>(columns[0]);
  const [sortDirection, setSortDirection] = useState(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setData(orders);
    handleSort(orders, sortColumn, sortDirection);
  }, [orders]);

  useEffect(() => {
    if (!isOpen) {
      if (Object.keys(selectedFilters).every(filter => selectedFilters[filter].length === 0)) {
        setAppliedFilters({});  // No selected filters
      } else {
        setAppliedFilters(selectedFilters);
      }
    }
  }, [isOpen]);

  function handleSort(
    data: DataType,
    column: TableColumn,
    sortDirection: number
  ): void {
    const newData = sortDataByColumn(data, column, sortDirection);
    setData(newData);
    setSortColumn(column);
    setSortDirection(sortDirection * -1);
  }

  const getFilterOptions = () => {
    const filterOptions: Record<string, string[]> = {};
    columns.filter(c => columnsWithFilters.includes(c.key)).forEach(column => {
      const uniqueValues = [...new Set(fullOrders.map(item => item[column.key as keyof Order]))];
      filterOptions[column.key] = uniqueValues as string[];
    });
    return filterOptions;
  }

  const tableTitle = 'Orders';

  if (isLoading) {
    return (
      <TableLoader
        columns={columns.map((column) => column.label)}
        title={tableTitle}
        handleRefresh={handleRefresh}
      />
    );
  }

  return (
    <TableContainer dataCy="orders-table">
        <TableHeader title="Orders">
          <RefreshButton
            handleRefresh={handleRefresh}
            dataCy="refresh-button-orders"
          />
          <FilterButton onClick={() => setIsOpen(prev => !prev)} />
          <div hidden={true}>
            <ModalBasic
              title='Filter Options'
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              dataCy='modal-filter-options'
            >
              <FilterModal
                filterOptions={getFilterOptions()}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                onApply={() => setIsOpen(false)}
              />
            </ModalBasic>
          </div>
        </TableHeader>
        <Table>
          <TableHead
            columns={columns}
            handleSort={(col) => {
              handleSort(data, col, sortDirection);
            }}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
          />
          {selectedLocation !== null && (
            <TableBody
              columns={columns}
              data={data}
              location={selectedLocation}
            />
          )}
        </Table>
    </TableContainer>
  );
}
