/**
 * Dropdown for selecting a single location.
 */
import { useData } from '../../hooks/useData';
import { AVAILABLE_FILTERS } from '@/constants';

import DropdownFilter from './DropdownFilter';

export default function DropdownStacksAggregate(): JSX.Element {
  const {
    selectedStackDriveOffFilter,
    setSelectedStackDriveOffFilter,
    handleFilter,
  } = useData();

  if (selectedStackDriveOffFilter === null || selectedStackDriveOffFilter === undefined) {
    return <></>;
  }

  return (
    <DropdownFilter
      title={selectedStackDriveOffFilter}
      options={AVAILABLE_FILTERS.map((filter: string) => ({
        id: filter,
        value: filter
      }))}
      selected={[
        {
          id: selectedStackDriveOffFilter,
          value: selectedStackDriveOffFilter
        },
      ]}
      handleChange={(id: string) => {
        if (id === undefined) {
          return;
        }
        setSelectedStackDriveOffFilter(id);
      }}
      filterFunction={handleFilter}
      dataCy="dropdown-stackdriveoff-single"
    />
  );
}
