/**
 * Presentational component for downloading videos.
 */
import SaveIcon from '@mui/icons-material/Save';
import { useCamera } from '../../hooks/useCamera';

export default function DownloadButton(): JSX.Element {
  const { setIsDownloading } = useCamera();
  return (
    <button
      className="btn border-gray-200 dark:border-slate-700 bg-white dark:bg-slate-800 hover:border-gray-300 text-slate-500 dark:text-slate-400 w-6/12 sm:w-auto"
      onClick={() => {
        setIsDownloading(true);
      }}
      data-cy="download-button"
    >
      <SaveIcon className="dark:text-slate-400" />
      <span className="ml-2">Download</span>
    </button>
  );
}
