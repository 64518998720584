export default function SectionTitle({
  title,
}: {
  title: string;
}): JSX.Element {
  return (
    <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">
      {title}
    </h2>
  );
}
