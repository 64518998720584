/**
 * Modal for rearranging camera order.
 *
 * https://codesandbox.io/p/sandbox/github/react-dnd/react-dnd/tree/gh-pages/examples_ts/01-dustbin/single-target?file=%2Fsrc%2Findex.tsx&from-embed=
 * https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_ts/01-dustbin/multiple-targets?from-embed
 * https://codesandbox.io/p/sandbox/github/react-dnd/react-dnd/tree/gh-pages/examples_ts/04-sortable/stress-test?from-embed=
 */
import { useState, useEffect } from 'react';
import ModalBasic from '../../modals/ModalBasic';
import CameraCard from './CameraCard';
import { useCamera } from '../../../hooks/useCamera';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import StandardButton from '../../buttons/StandardButton';
import type { Camera } from '../../../types/Camera';
import { useData } from '../../../hooks/useData';

interface CardState {
  cardsById: Record<string, Camera>;
  cardsByIndex: Camera[];
}

export default function CameraOrderModal(): JSX.Element {
  const { selectedLocation } = useData();
  const {
    isEditing,
    setIsEditing,
    availableCameras,
    cameraOrder,
    setCameraOrder,
  } = useCamera();
  const [cardState, setCardState] = useState<CardState>(buildCardData());

  useEffect(() => {
    setCardState(buildCardData());
  }, [availableCameras, isEditing]);

  function buildCardData(): CardState {
    const cameras = availableCameras.filter((camera) => {
      return camera.displayName !== 'All Cameras';
    });
    cameras.sort((a, b) => {
      const aIndex = cameraOrder.indexOf(a.id);
      const bIndex = cameraOrder.indexOf(b.id);
      return aIndex - bIndex;
    });
    const cardsById: Record<string, Camera> = {};
    const cardsByIndex: Camera[] = [];

    cameras.forEach((camera) => {
      cardsById[camera.id] = camera;
      cardsByIndex.push(camera);
    });

    return {
      cardsById,
      cardsByIndex,
    };
  }

  function moveCard(id: string, afterId: string): void {
    const { cardsById, cardsByIndex } = cardState;

    const card = cardsById[id];
    const afterCard = cardsById[afterId];
    const cardIndex = cardsByIndex.indexOf(card);
    const afterIndex = cardsByIndex.indexOf(afterCard);

    setCardState((state) => {
      const newCardsById = { ...state.cardsById };
      const newCardsByIndex = [...state.cardsByIndex];
      newCardsByIndex.splice(cardIndex, 1);
      newCardsByIndex.splice(afterIndex, 0, card);
      return {
        cardsById: newCardsById,
        cardsByIndex: newCardsByIndex,
      };
    });
  }

  function handleSave(): void {
    const newOrder = cardState.cardsByIndex.map((camera) => camera.id);
    if (selectedLocation === null) {
      return;
    }
    localStorage.setItem(
      `location-${selectedLocation.id}-camera-order`,
      JSON.stringify(newOrder)
    );
    setCameraOrder(newOrder);
    setIsEditing(false);
  }

  return (
    <ModalBasic
      title="Camera Order"
      isOpen={isEditing}
      setIsOpen={setIsEditing}
    >
      <DndProvider backend={HTML5Backend}>
        <p>Drag and drop cameras to rearrange the order.</p>
        {cardState.cardsByIndex.map((camera) => {
          return (
            <CameraCard key={camera.id} id={camera.id} moveCard={moveCard} />
          );
        })}
      </DndProvider>
      <StandardButton onClick={handleSave}>Save</StandardButton>
    </ModalBasic>
  );
}
