/**
 * Presentational component for selecting a camera.
 */

import type { Camera } from '../../types/Camera';
import DropdownFull from '../dropdowns/DropdownFull';
import { useCamera } from '../../hooks/useCamera';

export default function CameraSelector(): JSX.Element {
  const { availableCameras, selectedCamera, handleSelectedCameraChange } =
    useCamera();

  if (selectedCamera === null) {
    return <></>;
  }

  return (
    <DropdownFull
      options={availableCameras.map((camera: Camera) => ({
        id: camera.id,
        value: camera.displayName,
      }))}
      selected={{
        id: selectedCamera.id,
        value: selectedCamera.displayName,
      }}
      handleChange={handleSelectedCameraChange}
      dataCy="camera-selector"
    />
  );
}
