/**
 * Utility functions for working with location data for a particular user.
 */
import type { Location } from '../types/Location';
import type { Franchise } from '../types/Franchise';
import type { DayPart } from '../types/DayPart';

/**
 * @description Gets a list of all franchises from a list of locations.
 */
export function getFranchisesFromLocations(locations: Location[]): Franchise[] {
  const franchises: Franchise[] = [];

  locations.forEach((location) => {
    if (
      franchises.find((franchise) => franchise.id === location.franchiseId) ===
      undefined
    ) {
      franchises.push({
        id: location.franchiseId,
        displayName: location.franchiseName,
        configuration: {
          lobbyConfiguration: location.lobbyConfiguration!, 
          driveThruConfiguration: location.dtConfiguration!
        }
      });
    }
  });

  return franchises;
}

/**
 * @description Gets a list of missing location IDs from two lists of locations.
 * @param a - The first list of location IDs.
 * @param b - The second list of location IDs.
 * @returns - A list of location IDss that are in `a` but not in `b`.
 */
export function getMissingLocationIds(a: string[], b: string[]): string[] {
  return a.filter((location) => b.find((l) => l === location) === undefined);
}

/**
 * @description Gets a list of all day parts from a list of locations.
 * @param locations - The list of locations to get day parts from.
 * @param toggled - The toggled state of the locations.
 * @returns - A list of all day parts from the locations.
 */
export function getAllDayPartsFromLocations(
  locations: Location[],
  toggled: 'drive-thru' | 'lobby'
): Record<string, DayPart> {
  const dayParts: Record<string, DayPart> = {};

  locations.forEach((location: Location) => {
    if (toggled === 'drive-thru' && location.dtConfiguration !== undefined && location.dtConfiguration !== null) {
      location.dtConfiguration.dayParts.forEach((dayPart) => {
        if (dayParts[dayPart.name] === undefined) {
          dayParts[dayPart.name] = dayPart;
        }
      });
    }
    if (toggled === 'lobby' && location.lobbyConfiguration !== undefined && location.lobbyConfiguration !== null) {
      location.lobbyConfiguration.dayParts.forEach((dayPart) => {
        if (dayParts[dayPart.name] === undefined) {
          dayParts[dayPart.name] = dayPart;
        }
      });
    }
  });

  return dayParts;
}
