/**
 * Custom React hook for managing cameras.
 */
import { createContext, useContext, useState } from 'react';
import type { KinesisVideo } from 'aws-sdk';
import type { Camera } from '../types/Camera';

export const DEFAULT_NUM_CAMERAS_DISPLAYED = 4;

// Interface for the camera context.
interface CameraContext {
  selectedCamera: Camera | null;
  setSelectedCamera: (camera: Camera | null) => void;
  handleSelectedCameraChange: (id: string) => void;
  visibleCameras: Camera[];
  setVisibleCameras: (cameras: Camera[]) => void;
  numCamerasDisplayed: number;
  setNumCamerasDisplayed: (num: number) => void;
  videoDateTime: Date;
  setVideoDateTime: (date: Date) => void;
  isLive: boolean;
  setIsLive: (isLive: boolean) => void;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  playbackSpeed: number;
  setPlaybackSpeed: (speed: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalPages: number;
  setTotalPages: (pages: number) => void;
  isDownloading: boolean;
  setIsDownloading: (isDownloading: boolean) => void;
  isGrid: boolean;
  setIsGrid: (isGrid: boolean) => void;
  kinesisVideo: KinesisVideo | undefined;
  setKinesisVideo: (kinesisVideo: KinesisVideo) => void;
  availableCameras: Camera[];
  setAvailableCameras: (cameras: Camera[]) => void;
  showCvToggle: boolean;
  setShowCvToggle: (showCvToggle: boolean) => void;
  showCvStream: boolean;
  setShowCvStream: (showCvStream: boolean) => void;
  showCvDtStream: boolean;
  setShowCvDtStream: (showCvDtStream: boolean) => void;
  showMonitorStream: boolean;
  setShowMonitorStream: (showMonitorStream: boolean) => void;
  hasCvStream: boolean;
  setHasCvStream: (hasCvStream: boolean) => void;
  hasCvDtStream: boolean;
  setHasCvDtStream: (hasMonitorStream: boolean) => void;
  hasMonitorStream: boolean;
  setHasMonitorStream: (hasMonitorStream: boolean) => void;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  cameraOrder: string[];
  setCameraOrder: (order: string[]) => void;
}

// Dummy context
const dummyContext: CameraContext = {
  selectedCamera: null,
  setSelectedCamera: (camera: Camera | null) => {},
  handleSelectedCameraChange: (id: string) => {},
  visibleCameras: [],
  setVisibleCameras: (cameras: Camera[]) => {},
  numCamerasDisplayed: DEFAULT_NUM_CAMERAS_DISPLAYED,
  setNumCamerasDisplayed: (num: number) => {},
  videoDateTime: new Date(),
  setVideoDateTime: (date: Date) => {},
  isLive: true,
  setIsLive: (isLive: boolean) => {},
  isPlaying: true,
  setIsPlaying: (isPlaying: boolean) => {},
  playbackSpeed: 1,
  setPlaybackSpeed: (speed: number) => {},
  currentPage: 1,
  setCurrentPage: (page: number) => {},
  totalPages: 0,
  setTotalPages: (pages: number) => {},
  isDownloading: false,
  setIsDownloading: (isDownloading: boolean) => {},
  isGrid: false,
  setIsGrid: (isGrid: boolean) => {},
  kinesisVideo: undefined,
  setKinesisVideo: (kinesisVideo: KinesisVideo) => {},
  availableCameras: [],
  setAvailableCameras: (cameras: Camera[]) => {},
  showCvToggle: false,
  setShowCvToggle: (showCvToggle: boolean) => {},
  showCvStream: false,
  setShowCvStream: (showCvStream: boolean) => {},
  showCvDtStream: false,
  setShowCvDtStream: (showCvDtStream: boolean) => {},
  showMonitorStream: false,
  setShowMonitorStream: (showCvDtStream: boolean) => {},
  hasCvStream: false,
  setHasCvStream: (hasCvStream: boolean) => {},
  hasCvDtStream: false,
  setHasCvDtStream: (hasCvDtStream: boolean) => {},
  hasMonitorStream: false,
  setHasMonitorStream: (hasCvDtStream: boolean) => {},
  isEditing: false,
  setIsEditing: (isEditing: boolean) => {},
  cameraOrder: [],
  setCameraOrder: (order: string[]) => {},
};

// Create the camera context.
const cameraContext = createContext(dummyContext);

// Create the camera provider.
export const ProvideCamera: React.FC = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const camera = useProvideCamera();
  return (
    <cameraContext.Provider value={camera}>{children}</cameraContext.Provider>
  );
};

// Create the useCamera hook.
export const useCamera = (): CameraContext => {
  return useContext(cameraContext);
};

// Create the useProvideCamera hook.
const useProvideCamera = (): CameraContext => {
  const [selectedCamera, setSelectedCamera] = useState<Camera | null>(null);
  const [visibleCameras, setVisibleCameras] = useState<Camera[]>([]);
  const [numCamerasDisplayed, setNumCamerasDisplayed] = useState<number>(
    DEFAULT_NUM_CAMERAS_DISPLAYED
  );
  const [videoDateTime, setVideoDateTime] = useState<Date>(new Date());
  const [isLive, setIsLive] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isGrid, setIsGrid] = useState<boolean>(true);
  const [kinesisVideo, setKinesisVideo] = useState<KinesisVideo>();
  const [availableCameras, setAvailableCameras] = useState<Camera[]>([]);
  const [showCvToggle, setShowCvToggle] = useState<boolean>(false);
  const [showCvStream, setShowCvStream] = useState<boolean>(false);
  const [showCvDtStream, setShowCvDtStream] = useState<boolean>(false);
  const [showMonitorStream, setShowMonitorStream] = useState<boolean>(false);
  const [hasCvStream, setHasCvStream] = useState<boolean>(false);
  const [hasCvDtStream, setHasCvDtStream] = useState<boolean>(false);
  const [hasMonitorStream, setHasMonitorStream] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [cameraOrder, setCameraOrder] = useState<string[]>([]);

  // Handle changing the selected camera.
  function handleSelectedCameraChange(id: string): void {
    const camera = availableCameras.find((camera) => camera.id === id);
    if (camera !== undefined) {
      setSelectedCamera(camera);
    }

    if (id === '-1') {
      setVisibleCameras(availableCameras);
    } else if (camera !== undefined) {
      setVisibleCameras([camera]);
    }
  }

  return {
    selectedCamera,
    setSelectedCamera,
    handleSelectedCameraChange,
    visibleCameras,
    setVisibleCameras,
    numCamerasDisplayed,
    setNumCamerasDisplayed,
    videoDateTime,
    setVideoDateTime,
    isLive,
    setIsLive,
    isPlaying,
    setIsPlaying,
    playbackSpeed,
    setPlaybackSpeed,
    currentPage,
    setCurrentPage,
    totalPages,
    setTotalPages,
    isDownloading,
    setIsDownloading,
    isGrid,
    setIsGrid,
    kinesisVideo,
    setKinesisVideo,
    availableCameras,
    setAvailableCameras,
    showCvToggle,
    setShowCvToggle,
    showCvStream,
    setShowCvStream,
    showCvDtStream,
    setShowCvDtStream,
    showMonitorStream,
    setShowMonitorStream,
    hasCvStream,
    setHasCvStream,
    hasCvDtStream,
    setHasCvDtStream,
    hasMonitorStream,
    setHasMonitorStream,
    isEditing,
    setIsEditing,
    cameraOrder,
    setCameraOrder,
  };
};
