import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import { Amplify, Auth } from 'aws-amplify';
import awsExports from './aws-exports';

import './css/style.css';
// import './css/labelme.css';  remove to fix css

import './charts/ChartjsConfig';
import { useAuth } from './hooks/useAuth';
import { getUserProfile } from './utils/api';

// Import pages
import Dashboard from './pages/dashboard/Dashboard';
import Analytics from './pages/dashboard/Analytics';
import Stacks from './pages/dashboard/Stacks';
import Orders from './pages/dashboard/Orders';
import Cameras from './pages/dashboard/Cameras';
import AccountSettings from './pages/dashboard/settings/Account';
import LocationSettings from './pages/dashboard/settings/Location';
import ForgotPasswordRequest from './pages/auth/forgot-password/ForgotPasswordRequest';
import ForgotPasswordSubmit from './pages/auth/forgot-password/ForgotPasswordSubmit';
import Signin from './pages/auth/Signin';
import ChangePasswordPage from './pages/auth/ChangePassword';
import { useData } from './hooks/useData';
import { Roles } from './types/Roles';
import Admin from './pages/dashboard/settings/admin/Admin';
import LabelMe from './pages/dashboard/LabelMe';
import MonitorViewer from './pages/dashboard/MonitorViewer';
import Mothership from './pages/dashboard/Mothership';
import { PANDAFRANCHISE } from './constants';

Amplify.configure(awsExports);
Auth.configure(awsExports);

function App(): JSX.Element {
  const auth = useAuth();
  const location = useLocation();
  const { profile, selectedFranchise, handleProfileChange } = useData();
  const isPanda = !!profile?.franchises.find(franchise => franchise.id === PANDAFRANCHISE)

  useEffect(() => {
    window.scroll({ top: 0 });
  }, [location.pathname]); // triggered on route change

  // Get the user's profile data.
  useEffect(() => {
    async function getProfileData(): Promise<void> {
      if (auth.user !== null) {
        const profile = await getUserProfile();
        handleProfileChange(profile);
        if (localStorage.getItem('defaultLocation') === null) {
          localStorage.setItem('defaultLocation', profile.locations[0].id);
        }
      }
    }
    getProfileData().catch((error) => {
      console.error(error);
    });
  }, [auth.user]);

  if (auth.isLoading) {
    return <></>;
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/analytics"
          element={
            <PrivateRoute>
              <Analytics />
            </PrivateRoute>
          }
        />
        {/* For testing the new analytics page, not intended for prolonged use */}
        <Route
          path="/analyticsv2"
          element={
            <PrivateRoute>
              <Analytics version={"v2"} />
            </PrivateRoute>
          }
        />
        {isPanda && (
          <Route
            path="/stacks"
            element={
              <PrivateRoute>
                <Stacks />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="/orders"
          element={
            <PrivateRoute>
              <Orders />
            </PrivateRoute>
          }
        />
        <Route
          path="/cameras"
          element={
            <PrivateRoute>
              <Cameras />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/account"
          element={
            <PrivateRoute>
              <AccountSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/locations"
          element={
            <PrivateRoute>
              <LocationSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/labelme"
          element={
            // <PrivateRoute>
            <LabelMe />
            // </PrivateRoute>
          }
        />
        <Route
          path="/monitorviewer"
          element={
            <MonitorViewer />
          }
        />
        <Route
          path="/mothership"
          element={
            <Mothership />
          }
        />
        {profile?.franchiseRoles.get(selectedFranchise?.id ?? '') ===
          Roles.ADMIN && (
          <Route
            path="/settings/admin"
            element={
              <PrivateRoute>
                <Admin />
              </PrivateRoute>
            }
          />
        )}
        <Route path="/forgot-password" element={<ForgotPasswordRequest />} />
        <Route
          path="/forgot-password/submit"
          element={<ForgotPasswordSubmit />}
        />
        <Route path="/signin" element={<Signin />} />
        <Route path="/change-password" element={<ChangePasswordPage />} />
      </Routes>
    </>
  );
}

export default App;
