/**
 * Presentational component for jumping to live.
 */
import { useCamera } from '../../hooks/useCamera';

export default function LiveButton(): JSX.Element {
  const { isLive, setIsLive, setVideoDateTime } = useCamera();

  return (
    <button
      className={`btn 
        focus:outline-none
        sm:w-auto
        w-6/12
        ${isLive ? 'text-white' : 'text-red-500 dark:text-red-200'}
        ${isLive ? 'bg-red-500' : 'bg-white dark:bg-slate-800'}
        border-red-100 hover:border-red-200 dark:border-none`}
      onClick={() => {
        setVideoDateTime(new Date());
        setIsLive(!isLive);
      }}
      data-cy="live-button"
    >
      Live
    </button>
  );
}
