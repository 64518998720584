/**
 * Dropdown for selecting multiple locations.
 */
import { useData } from '../../hooks/useData';
import type { Location } from '../../types/Location';

import DropdownFilter from './DropdownFilter';

export default function DropdownAnalysisLocationMultiple({
  dataCy = 'dropdown-analysis-location-multiple',
}: {
  dataCy?: string;
}): JSX.Element {
  const {
    displayedLocations,
    selectedAnalysisLocations,
    handleSelectedAnalysisLocationsChange,
    handleFilter,
    handleSelectAllAnalytics,
    handleDeselectAllAnalytics,
  } = useData();

  return (
    <DropdownFilter
      title={`${selectedAnalysisLocations.length} Locations`}
      options={displayedLocations.map((location: Location) => ({
        id: location.id,
        value: location.displayName,
      }))}
      selected={selectedAnalysisLocations.map((location) => ({
        id: location.id,
        value: location.displayName,
      }))}
      handleChange={handleSelectedAnalysisLocationsChange}
      filterFunction={handleFilter}
      selectAllFunction={handleSelectAllAnalytics}
      deselectAllFunction={handleDeselectAllAnalytics}
      dataCy={dataCy}
    />
  );
}
