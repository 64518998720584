/**
 * Presentational component for a table value.
 */

import type { TableColumn } from '../../types/TableColumn';
import { formatSeconds } from '../../utils/time';
import { toUSDMoneyFormat } from '../../utils/money';
import ArrivalTimeLink from '../ui/ArrivalTimeLink';
import type { Location } from '../../types/Location';
import { Link } from 'react-router-dom';
import ArrivalLocalTime from '../ui/ArrivalLocalTime';
import ArrivalTimeLinkLocal from '@/components/ui/ArrivalTimeLinkLocal'


// Wrapper component for a table value.
function Wrapper({
  children,
  column,
  isBestValue,
  isWorstValue,
}: {
  children: React.ReactNode;
  column: TableColumn;
  isBestValue?: boolean;
  isWorstValue?: boolean;
}): JSX.Element {
  if (isBestValue !== undefined && isBestValue) {
    return (
      <td className="p-1" data-cy={column.key}>
        <div className="btn text-sm text-white bg-green-500 p-0 px-2">
          {children}
        </div>
      </td>
    );
  }

  if (isWorstValue !== undefined && isWorstValue) {
    return (
      <td className="p-1" data-cy={column.key}>
        <div className="btn text-sm text-white bg-red-500 p-0 px-2">
          {children}
        </div>
      </td>
    );
  }

  return (
    <td className="p-1" data-cy={column.key}>
      {children}
    </td>
  );
}

export default function TableValue({
  value,
  column,
  location,
  isBestValue,
  isWorstValue,
  href,
}: {
  value: any;
  column: TableColumn;
  location?: Location;
  isBestValue?: boolean;
  isWorstValue?: boolean;
  href?: string;
}): JSX.Element {
  function getColor(): string {
    if (
      (column.type === 'numberDifference' ||
        column.type === 'secondsDifference') &&
      value < 0
    ) {
      return 'text-red-500';
    }
    if (
      (column.type === 'numberDifference' ||
        column.type === 'secondsDifference') &&
      value > 0
    ) {
      return 'text-green-500';
    }

    return '';
  }

  function formatNumber(value: number): string | number {
    if (value === 0) {
      return '--';
    }
    return value;
  }

  return (
    <Wrapper
      column={column}
      isBestValue={isBestValue}
      isWorstValue={isWorstValue}
    >
      {column.type === 'link' && href !== undefined && (
        <Link to={href}>{value}</Link>
      )}
      {column.type === 'seconds' && formatSeconds(value as number)}
      {column.type === 'number' && formatNumber(value as number)}
      {column.type === 'string' && value}
      {column.type === 'time' && location !== undefined && (
        <>{new Date(value as string).toLocaleTimeString()}</>
      )}
      {column.type === 'date' && location !== undefined && (
        <ArrivalTimeLink utcTime={value} location={location} />
      )}
      {column.type === 'date-local' && location !== undefined && (
        <ArrivalLocalTime utcTime={value} location={location} />
      )}
      {column.type === 'USD' && toUSDMoneyFormat(value as number)}
      {column.type === 'secondsDifference' && (
        <span className={getColor()}>{formatSeconds(value as number)}</span>
      )}
      {column.type === 'numberDifference' && (
        <span className={getColor()}>{formatNumber(value as number)}</span>
      )}
      {column.type === 'local-date-with-location' && value && (
        <ArrivalTimeLinkLocal utcTime={value.timestamp} location={value.location} />
      )}

    </Wrapper>
  );
}
