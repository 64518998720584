/**
 * Utility functions for working with time.
 */
import { getTimezoneOffset } from 'date-fns-tz';

/**
 * @function formatSeconds
 * @description Formats an integer number of seconds into a string in minutes:seconds format.
 */
export const formatSeconds = (numSeconds: number): string => {
  if (numSeconds == null || isNaN(numSeconds) || numSeconds === 0) {
    return '--';
  }
  const minutes = Math.floor(Math.abs(numSeconds) / 60);
  const seconds = Math.round(Math.abs(numSeconds) % 60);

  let formattedString = numSeconds < 0 ? '-' : '';

  if (minutes === 0) {
    formattedString += `${seconds < 10 ? '0' + seconds : seconds}s`;
  } else {
    formattedString += `${minutes}m ${seconds < 10 ? '0' + seconds : seconds}s`;
  }
  return formattedString;
};

/**
 * @function utcStringToLocalDate
 * @description Converts a UTC date string to a local date string.
 * */
export const utcStringToLocalDate = (
  utcString: string,
  timeZone: string
): Date => {
  // Split
  const dt = utcString.split(/[: T-]/).map(parseFloat);

  const d = Date.UTC(dt[0], dt[1] - 1, dt[2], dt[3], dt[4], dt[5]);
  const localString = new Date(d).toLocaleString('en-US', {
    timeZone,
  });

  // Now back to a datetimeobject that is tz wrong, but all else good
  const nd = new Date(localString);
  return nd;
};

/**
 * Takes a local date (e.g., new Date) that should be in the given timezone,
 * and converts it to UTC for use in querries
 * @param {Date} date
 * @param {string} localTimezone
 */
export function dateToUtc(date: Date, localTimezone: string): Date {
  const localOffset = -1 * date.getTimezoneOffset() * 60 * 1000;
  const baseOffset = getTimezoneOffset(localTimezone);
  const adjusted = new Date(date.valueOf() - (baseOffset - localOffset));
  return adjusted;
}

/**
 * Takes a shorthand month string (e.g., 'Jan') and returns the month number (1-12).
 * @param shortMonth - The shorthand month string.
 * @returns = The month number (1-12).
 */
export function getMonthNumber(shortMonth: string): number {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return months.indexOf(shortMonth) + 1;
}
