/**
 * Location Settings Page
 * This page is intended for customers to manage their location settings.
 */
import PageLayout from '../../../components/ui/PageLayout';
import SettingsSidebar from '../../../components/settings/SettingsSidebar';
import LocationPanel from '../../../components/settings/locations/LocationPanel';

import { useData } from '../../../hooks/useData';

function Locations(): JSX.Element {
  const { userLocations } = useData();

  return (
    <PageLayout>
      <>
        {/* Page header */}
        <div className="mb-8">
          {/* Title */}
          <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
            Location Settings ✨
          </h1>
        </div>

        {/* Content */}
        <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
          <div className="flex flex-col md:flex-row md:-mr-px">
            <SettingsSidebar />
            <div className="flex flex-col w-full">
              {userLocations.map(
                (location) =>
                  location.role === 'ADMIN' && (
                    <LocationPanel key={location.id} location={location} />
                  )
              )}
            </div>
          </div>
        </div>
      </>
    </PageLayout>
  );
}

export default Locations;
