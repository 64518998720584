/**
 * Help modal for the analytics page.
 */
import ModalBasic from './ModalBasic';

export default function HelpModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}): JSX.Element {
  return (
    <ModalBasic
      title="Analytics Guide"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dataCy="help-modal"
    >
      <div className="flex flex-col w-full h-96 p-4 overflow-scroll">
        <h2 className="text-xl text-gray-600 font-semibold">Overview</h2>
        <p>
          Up to 10 locations can be selected at a time. All of the selected
          locations will be available in each visualization. In the “dates”
          dropdown, a start and end date can be selected. Data is aggregated to
          the day level.
        </p>
        <br />
        <p>
          If you have locations with both drive-thrus and lobbies, a toggle will
          appear to allow you to choose which you would like to view. If your
          locations have only one or the other this is taken care of
          automatically.
        </p>
        <hr className="mb-4" />
        <h2 className="text-xl text-gray-600 font-semibold">Trend Chart</h2>
        <p>
          This visualization shows the 7-day rolling average service time for
          all selected locations. The toggle enables you to select if you would
          like to see service times, volumes, or both. The left hand side Y
          (vertical) Axis shows service times, and the right hand side Y Axis
          shows volumes (if selected). If volumes are being shown they will be
          denoted with a dashed (as opposed to solid) line.
        </p>
        <br />
        <p>
          With multiple locations, as well as both volumes and times showing, it
          can get a bit cluttered. To help make sense of trends, hovering over a
          location in the legend with the mouse will highlight that
          location&apos;s lines and lowlight everywhere else.
        </p>
        <hr className="mb-4" />
        <h2 className="text-xl text-gray-600 font-semibold">Stations Chart</h2>
        <p>
          Here you can see performance across locations and stations within each
          location. By default, this chart shows each location across the
          horizontal axis, and a cluster of bars at each location, where each
          bar represents a certain station. The colors are shown in the legend.
          Hovering over a bar with the mouse will show that corresponding time
          at the top of the bar. Each bar represents the average time for the
          selected period.
        </p>
        <br />
        <p>
          Clicking on any individual bar will drill down to show only that
          station across all locations--helping to make comparisons across
          locations easier. This can also be accomplished by clicking on any
          legend entry, at any time.
        </p>
        <br />
        <p>
          Clicking on a location name on the X Axis will drill down to show the
          service time trend for the stations at that location over the selected
          time period. Hovering anywhere on the graph in this view will produce
          a tooltip showing the time for each station at the date (which is
          indicated with a vertical line down to the X Axis). All times shown in
          this trend view are a 7-day rolling average.
        </p>
        <br />
        <p>
          In any view, <b>double clicking</b> anywhere on the chart will bring
          you back to the default view.
        </p>
        <hr className="mb-4" />
        <h2 className="text-xl text-gray-600 font-semibold">Day Parts Chart</h2>
        <p>
          This visualization is very similar to the Stations Chart, except that
          it shows average service times across day parts and has the ability to
          show volumes.
        </p>
        <br />
        <p>
          By default, locations are shown across the X Axis. Around each is
          clustered a group of bars, each of which represent a day part.
          Hovering over a bar will show the corresponding time at the top of the
          bar. Clicking on any individual bar (or a legend entry) will drill
          down to focus on that specific day part across all locations for quick
          comparison.
        </p>
        <br />
        <p>
          Clicking on a location on the X Axis will drill down to show the day
          part trends for that location. Each line represents a 7-day rolling
          average of either service time during that day part or customer
          volume.
        </p>
        <br />
        <p>
          In all focus levels, the toggle in the top right can be used to show
          either service times or customer volumes. Any in any view,{' '}
          <b>double clicking</b> anywhere will bring you back to the default
          view.
        </p>
      </div>
    </ModalBasic>
  );
}
