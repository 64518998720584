/**
 * Hook to provide toast data.
 */
import { createContext, useContext, useState } from 'react';

interface ToastDataType {
  show: boolean;
  message: string;
  type: 'success' | 'error' | 'warning' | '';
}

interface UseToast {
  toast: ToastDataType;
  setToast: React.Dispatch<React.SetStateAction<ToastDataType>>;
}

const dummyContext: UseToast = {
  toast: {
    show: false,
    message: '',
    type: '',
  },
  setToast: () => {},
};

const ToastContext = createContext(dummyContext);

export const ProvideToast: React.FC = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const toast = useProvideToast();
  return (
    <ToastContext.Provider value={toast}>{children}</ToastContext.Provider>
  );
};

export const useToast = (): UseToast => {
  return useContext(ToastContext);
};

function useProvideToast(): UseToast {
  const [data, setData] = useState<ToastDataType>({
    show: false,
    message: '',
    type: '',
  });

  return {
    toast: data,
    setToast: setData,
  };
}
