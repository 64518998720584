/**
 * Presentational component for the day parts graph.
 */

import { useState, useRef, useEffect } from 'react';
import { chartColors } from '../../../charts/ChartjsConfig';
import {
  Chart,
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
} from 'chart.js';
import type { ChartData } from 'chart.js';
import 'chartjs-adapter-moment';
import { formatSeconds } from '../../../utils/time';
import type { DayPartData } from '../../../types/DayPartData';
import { tailwindConfig } from '../../../utils/Utils';
import { useData } from '../../../hooks/useData';
import { getAllDayPartsFromLocations } from '../../../utils/locations';

Chart.register(
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend
);

export default function DayPartsGraph({
  dayPartsData,
}: {
  dayPartsData: DayPartData[];
}): JSX.Element {
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const { selectedLocations, toggled } = useData();

  useEffect(() => {
    setChartData(getChartData(dayPartsData));
  }, [dayPartsData]);

  function getChartData(rawData: DayPartData[]): ChartData {
    const labels = rawData.map((data) => data.locationName);

    const dataByDayPart: any = {};

    for (const data of rawData) {
      for (const dayPart of data.dayParts) {
        if (
          dataByDayPart[dayPart.name] === undefined ||
          dataByDayPart[dayPart.name] === null
        ) {
          dataByDayPart[dayPart.name] = [];
        }
        dataByDayPart[dayPart.name].push(dayPart.averageServiceTime);
      }
    }

    const tailwindColors = tailwindConfig().theme?.colors as Record<
      string,
      any
    >;

    const colors: any[] = [
      tailwindColors.amber,
      tailwindColors.blue,
      tailwindColors.emerald,
      tailwindColors.fuchsia,
      tailwindColors.rose,
      tailwindColors.lime,
      tailwindColors.orange,
      tailwindColors.cyan,
      tailwindColors.sky,
      tailwindColors.violet,
      tailwindColors.red,
      tailwindColors.green,
      tailwindColors.purple,
      tailwindColors.pink,
      tailwindColors.indigo,
      tailwindColors.yellow,
      tailwindColors.gray,
      tailwindColors.teal,
      tailwindColors.zinc,
    ];

    // @todo fix this to avoid indexing at 0 when there is no data
    const allDayParts = getAllDayPartsFromLocations(selectedLocations, toggled);
    const datasets = Object.keys(allDayParts).map((name, index) => ({
      label: name,
      data: dataByDayPart[name] ?? 0,
      backgroundColor: colors[index][400],
      hoverBackgroundColor: colors[index][600],
      categoryPercentage: 0.66,
    }));
    return { labels, datasets };
  }
  const [chart, setChart] = useState<Chart | null>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const legend = useRef<HTMLUListElement>(null);
  const darkMode = false;

  const {
    textColor,
    gridColor,
    tooltipBodyColor,
    tooltipBgColor,
    tooltipBorderColor,
  } = chartColors;

  const width = 595;
  const height = 248;

  useEffect(() => {
    const ctx = canvas.current;
    if (ctx === null || chartData === null) return;

    const newChart = new Chart(ctx, {
      type: 'bar',
      data: chartData,
      options: {
        indexAxis: 'x',
        layout: {
          padding: {
            top: 12,
            bottom: 16,
            left: 72,
            right: 20,
          },
        },
        scales: {
          y: {
            border: {
              display: false,
            },
            grid: {
              display: false,
              drawTicks: false,
            },
            ticks: {
              color: darkMode ? textColor.dark : textColor.light,
              callback: (value) => formatSeconds(+value),
            },
          },
          x: {
            border: {
              display: false,
            },
            ticks: {
              color: darkMode ? textColor.dark : textColor.light,
            },
            grid: {
              color: darkMode ? gridColor.dark : gridColor.light,
            },
          },
        },
        plugins: {
          legend: {
            display: true,
          },
          tooltip: {
            callbacks: {
              title: () => '', // Disable tooltip title
              label: (context) => formatSeconds(context.parsed.y),
            },
            bodyColor: darkMode
              ? tooltipBodyColor.dark
              : tooltipBodyColor.light,
            backgroundColor: darkMode
              ? tooltipBgColor.dark
              : tooltipBgColor.light,
            borderColor: darkMode
              ? tooltipBorderColor.dark
              : tooltipBorderColor.light,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        animation: {
          duration: 500,
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
    });
    setChart(newChart);
    return () => {
      newChart.destroy();
    };
  }, [chartData]);

  useEffect(() => {
    if (chart === null) return;

    if (
      darkMode &&
      chart.options.scales?.x?.ticks !== undefined &&
      chart.options.scales?.x?.grid !== undefined &&
      chart.options.plugins?.tooltip !== undefined
    ) {
      chart.options.scales.x.ticks.color = textColor.dark;
      chart.options.scales.x.grid.color = gridColor.dark;
      chart.options.plugins.tooltip.bodyColor = tooltipBodyColor.dark;
      chart.options.plugins.tooltip.backgroundColor = tooltipBgColor.dark;
      chart.options.plugins.tooltip.borderColor = tooltipBorderColor.dark;
    } else if (
      chart.options.scales?.x?.ticks !== undefined &&
      chart.options.scales?.x?.grid !== undefined &&
      chart.options.plugins?.tooltip !== undefined
    ) {
      chart.options.scales.x.ticks.color = textColor.light;
      chart.options.scales.x.grid.color = gridColor.light;
      chart.options.plugins.tooltip.bodyColor = tooltipBodyColor.light;
      chart.options.plugins.tooltip.backgroundColor = tooltipBgColor.light;
      chart.options.plugins.tooltip.borderColor = tooltipBorderColor.light;
    }
    chart.update('none');
  }, []);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700 flex justify-between">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          Day Parts
        </h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <div className="px-5 py-4">
        <ul ref={legend} className="flex flex-wrap"></ul>
      </div>
      <div className="grow">
        <canvas
          ref={canvas}
          width={width}
          height={height}
          data-cy="day-parts-graph"
        ></canvas>
      </div>
    </div>
  );
}
