import HellometerAuthImage from '../../assets/images/hellometer/auth-image.png';
import AvsAuthImage from '../../assets/images/avs/auth-image.png';
import AuthDecoration from '../../assets/images/auth-decoration.png';

export default function AuthImage(): JSX.Element {
  return (
    <div
      className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
      aria-hidden="true"
    >
      <img
        className="object-cover object-center w-full h-full"
        src={
          window.location.href.includes('avs.hellometer.io')
            ? AvsAuthImage
            : HellometerAuthImage
        }
        width="760"
        height="1024"
        alt="Authentication"
      />
      <img
        className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
        src={AuthDecoration}
        width="218"
        height="224"
        alt="Authentication decoration"
      />
    </div>
  );
}
