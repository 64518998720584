/**
 * Account Settings Page
 * This page is intended for customers to manage their account settings.
 */
import PageLayout from '../../../components/ui/PageLayout';
import SettingsSidebar from '../../../components/settings/SettingsSidebar';
import AccountPanel from '../../../components/settings/account/AccountPanel';

function Account(): JSX.Element {
  return (
    <PageLayout>
      <>
        {/* Page header */}
        <div className="mb-8">
          {/* Title */}
          <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
            Account Settings ✨
          </h1>
        </div>

        {/* Content */}
        <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
          <div className="flex flex-col md:flex-row md:-mr-px">
            <SettingsSidebar />
            <AccountPanel />
          </div>
        </div>
      </>
    </PageLayout>
  );
}

export default Account;
