import UpdateDayPartsSection from '../../../../../components/settings/locations/sections/UpdateDayPartsSection';
import { useData } from '../../../../../hooks/useData';
import DTConfiguration from './DTConfiguration';
import EmailConfiguration from './EmailConfiguration';
import LobbyConfiguration from './LobbyConfiguration';
import RegionsConfiguration from './RegionsConfiguration';

const FranchiseConfiguration: React.FC = () => {
    const { 
        franchiseConfiguration,
        setFranchiseConfiguration
    } = useData();

    if (!franchiseConfiguration) return <div>Loading...</div>;

    return (
        <div>
            <EmailConfiguration/>
            {/* <DTConfiguration/>
            <LobbyConfiguration/>
            <RegionsConfiguration/> */}
            {/* <div>
                <label>Notification Threshold: </label>
                <input
                    type="number"
                    value={franchiseConfiguration.lobbyConfiguration.notificationThreshold}
                    onChange={e => handleInputChange(e, 'lobbyConfiguration', 'notificationThreshold')}
                />
            </div>
            <div>
                <label>Volume Threshold: </label>
                <input
                    type="number"
                    value={franchiseConfiguration.lobbyConfiguration.volumeThreshold}
                    onChange={e => handleInputChange(e, 'lobbyConfiguration', 'volumeThreshold')}
                />
            </div> */}
        </div>
    );
};

export default FranchiseConfiguration;
