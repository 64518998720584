/**
 * Utility functions for working with money.
 */

/**
 * Converts money to a string with a dollar sign and two decimal places.
 *
 * @param amount The amount of money to convert.
 * @returns A string with a dollar sign and two decimal places.
 */
export function toUSDMoneyFormat(amount: number): string {
  // Use the built-in Intl.NumberFormat to format the number in USD
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
}
