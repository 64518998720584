"use client"

import { useState, useEffect } from 'react';
import { DayPartData } from "@/types/DayPartData";
import { Bar, BarChart, CartesianGrid, TooltipProps, XAxis, YAxis } from "recharts"
import { formatSeconds } from '@/utils/time';
import { LocationData } from '@/types/TrendLineData';

import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
  } from "@/components/ui/card"
  import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
  } from "@/components/ui/chart"

// Data type interfaces
interface DayPartChartData {
  locationData: LocationData[];
  barData: BarData[];
}

interface BarData {
  dayPart: string;
  [id: string]: number | string; // locationId-time for service time at daypart
}

// Data type converters
function orderDayPartNames(data: DayPartChartData): DayPartChartData {
  // Hardcoded for miguel's
  const orderMap: { [key: string]: number } = {
    'Breakfast': 1,
    'Lunch': 2,
    'Afternoon': 3,
    'Evening': 4,
  };
  data.barData.sort((a, b) => {
      return (orderMap[a.dayPart] ?? Infinity) - (orderMap[b.dayPart] ?? Infinity);
  });
  return data
}

const chartConfig = {
  dayPart: {
    label: "Day Part",
  },
  time: {
    label: "Service Time"
  },
} satisfies ChartConfig

export function DayPartsChart({
    dayPartsData,
    colorMap,
  }: {
    dayPartsData: DayPartData[] | null;
    colorMap: { [key: string]: string };
  }): JSX.Element {
  const [chartData, setChartData] = useState<DayPartChartData | null>(null);
  const [dayPartNames, setDayPartNames] = useState<string[]>();
  const [flatData, setFlatData] = useState<any[]>();

  const createFlatBarData = (locationData: LocationData[] | undefined, names: string[] | undefined): any[] => {
    const flatData = new Array;
    locationData?.forEach((loc) => {
      names?.forEach(rName => {
        flatData.push({
          dataKey: `${loc.locationId}-${rName}`,
          key: `${loc.locationId}-${rName}`,
          name: `${loc.locationName}-${rName}`,
          fill: loc.color
        })
      });
    });
    return flatData;
  }

  // Convert DayPartData[] to DayPartChartData[]
  function convertToDayPartChartData(rawData: DayPartData[] | null): DayPartChartData {
    const newData = {
      locationData: new Array,
      barData: new Array,
    } as DayPartChartData;
    const dateMap = new Map();

    if (rawData) {
      // Add location data
      newData.locationData = rawData.map((data) => ({
        locationName: data.locationName,
        locationId: data.locationId,
        color: colorMap[data.locationId]
      }));
    
      // Add bar data for this location
      rawData.forEach(loc => {
        loc.dayParts.forEach(dp => {
          const serviceTimeKey = `${loc.locationId}-${dp.name}`;

          if (!dateMap.has(dp.name)) {
            dateMap.set(dp.name, {
              [serviceTimeKey]: dp.averageServiceTime,
            });
          } else {
            const existingData = dateMap.get(dp.name);
            existingData[serviceTimeKey] = dp.averageServiceTime;
          }
        });
      });
    }

    newData.barData = Array.from(dateMap.entries()).map(([dayPart, data]) => ({
      dayPart,
      ...data,
    }));

    return orderDayPartNames(newData);
  }

  const CustomTooltip: React.FC<TooltipProps<any, any>> = ({ active, payload }) => {
    if (active && payload && payload.length) {

      return (
        <div style={{ 
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          padding: '5px',
          borderRadius: '4px',
          fontSize: 'small'
        }}>
          {payload.map(pl => (
            <div key={pl.dataKey} style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: '1em',
                  height: '1em',
                  backgroundColor: pl.color,
                  marginRight: '0.5em'
                }}
              />
              <p>{`${pl.name} - Time: ${formatSeconds(pl.payload[pl?.dataKey || 0])}`}</p>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    // Create chart data
    const newChartData = convertToDayPartChartData(dayPartsData);
    // Set chart data
    setChartData(newChartData);
    // Set day part names
    const names = newChartData?.barData.map(bd => bd.dayPart);
    setDayPartNames(names);
    // Set flat version of bar data
    setFlatData(createFlatBarData(newChartData?.locationData, names))
  }, [dayPartsData]);

  if (!chartData || !dayPartNames) {
    return (<div>{null}</div>);
  }
  
  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <Card>
        <CardHeader>
          <CardTitle>Day Parts</CardTitle>
        </CardHeader>
        <CardContent>
          <ChartContainer config={chartConfig}>
            <BarChart accessibilityLayer data={chartData.barData}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="dayPart"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
              />
              <YAxis 
                label={{ value: "Time Spent", angle: -90, position: 'insideLeft' }}
                tickFormatter={(value) => formatSeconds(value)}
                />
              <ChartTooltip
                cursor={false}
                content={<CustomTooltip />}
              />
              {flatData?.map((data) => (
                <Bar 
                  dataKey={data.dataKey} 
                  key={data.key} 
                  name={data.name}
                  fill={data.fill}
                  radius={4} 
                  />
                )
              )}
            </BarChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  )
}
