// Import the functions you need from Firebase SDK
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

// export const app = initializeApp({
// apiKey: env.VITE_FIREBASE_API_KEY,
// authDomain: env.VITE_FIREBASE_AUTH_DOMAIN,
// databaseURL: env.VITE_FIREBASE_DATABASE_URL,
// projectId: env.VITE_FIREBASE_PROJECT_ID,
// storageBucket: env.VITE_FIREBASE_STORAGE_BUCKET,
// messagingSenderId: env.VITE_FIREBASE_MESSAGING_SENDER_ID,
// appId: env.VITE_FIREBASE_APP_ID,
// measurementId: env.VITE_FIREBASE_MEASUREMENT_ID
// });

const firebaseConfig =
{
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

  // Initialize Firebase
export const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
