/**
 * Presentational component for selecting a date.
 */
import Flatpickr from 'react-flatpickr';
import type { Options, Hook } from 'flatpickr/dist/types/options';
import { useCamera } from '../../hooks/useCamera';

export default function DateSelector(): JSX.Element {
  const { videoDateTime, setVideoDateTime, setIsLive } = useCamera();

  const options: Options = {
    mode: 'single',
    enableTime: true,
    dateFormat: 'M j, Y h:i K',
    disableMobile: true,
    prevArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    static: true,
  };

  const handleChange: Hook = (selectedDates, dateStr, instance) => {
    setIsLive(false);
    setVideoDateTime(selectedDates[0]);
  };

  return (
    <div className="relative px-12" data-cy="datepicker">
      <Flatpickr
        className="form-input dark:bg-slate-800 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200 font-medium"
        options={options}
        value={videoDateTime}
        onChange={handleChange}
      />
    </div>
  );
}
