/**
 * Presentational component for changing the display name.
 */
import SectionTitle from './SectionTitle';

export default function ChangeDisplayNameSection({
  displayName,
  handleInputChange,
}: {
  displayName: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element {
  return (
    <section>
      <SectionTitle title="Change Display Name" />
      <input
        type="text"
        value={displayName}
        className="form-input"
        onChange={handleInputChange}
        data-cy="display-name-input"
      />
    </section>
  );
}
