/**
 * Presentational component for toggling between grid and list view.
 */

import AppsIcon from '@mui/icons-material/Apps';
import ReorderIcon from '@mui/icons-material/Reorder';
import { useCamera } from '../../hooks/useCamera';

export default function GridListToggle(): JSX.Element {
  const { isGrid, setIsGrid } = useCamera();

  return (
    <div className="sm:flex hidden justify-center">
      <div className="m-3 flex flex-row rounded-lg p-2">
        <div className="inline-flex">
          <button
            className={`btn ${
              isGrid ? 'bg-white dark:bg-slate-800' : ''
            } focus:outline-none`}
            onClick={() => {
              setIsGrid(true);
            }}
            data-cy="grid-toggle-button"
          >
            <AppsIcon />
          </button>
          <button
            className={`btn ${
              !isGrid ? 'bg-white dark:bg-slate-800' : ''
            } focus:outline-none`}
            onClick={() => {
              setIsGrid(false);
            }}
            data-cy="list-toggle-button"
          >
            <ReorderIcon />
          </button>
        </div>
      </div>
    </div>
  );
}
