import { Auth } from 'aws-amplify';
import type { CognitoUserExtended } from '../types/CognitoUserExtended';

export async function getUser(): Promise<CognitoUserExtended | null> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user as CognitoUserExtended;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getUserToken(): Promise<string | null> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const session = await user.getSignInUserSession();
    return session.idToken.jwtToken;
  } catch (error) {
    console.error(error);
    return null;
  }
}
