/**
 * Presentational button component for refreshing data.
 */

export default function RefreshButton({
  handleRefresh,
  dataCy = 'refresh-button',
}: {
  handleRefresh: () => void;
  dataCy?: string;
}): JSX.Element {
  return (
    <button
      className="button bg-transparent hover:bg-blue-400
                                  text-blue-400 hover:text-white rounded-full
                                  border border-blue-400 py-1 px-2 text-xs"
      style={{ maxHeight: '2.5em' }}
      onClick={handleRefresh}
      data-cy={dataCy}
    >
      Refresh
    </button>
  );
}
