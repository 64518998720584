/**
 * https://dev.to/aws-builders/how-to-use-amazon-cognito-with-reacttypescript-4elj
 */
import { Auth } from 'aws-amplify';
import { createContext, useContext, useEffect, useState } from 'react';
import type { CognitoUserExtended } from '../types/CognitoUserExtended';

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: CognitoUserExtended | null;
  signIn: (username: string, password: string) => Promise<CognitoUserExtended>;
  signOut: () => Promise<{ success: boolean; message: string }>;
  sendResetPasswordEmail: (email: string) => Promise<boolean>;
  resetPasswordSubmit: (
    email: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
  completeNewPassword: (newPassword: string) => Promise<CognitoUserExtended>;
}

interface Props {
  children?: React.ReactNode;
}

const dummyUser: CognitoUserExtended = {
  attributes: {
    sub: '',
    email: '',
    email_verified: false,
  },
  getUsername: () => '',
  getSignInUserSession: () => null,
  getSession: () => null,
  getAuthenticationFlowType: () => '',
  setSignInUserSession: () => null,
  getCachedDeviceKeyAndPassword: () => null,
  setAuthenticationFlowType: () => '',
  initiateAuth: () => null,
  refreshSession: () => null,
  authenticateUser: () => null,
  forgotPassword: () => null,
  confirmRegistration: () => null,
  sendCustomChallengeAnswer: () => null,
  resendConfirmationCode: () => null,
  changePassword: () => null,
  confirmPassword: () => null,
  setDeviceStatusNotRemembered: () => null,
  setDeviceStatusRemembered: () => null,
  getDevice: () => null,
  forgetDevice: () => null,
  forgetSpecificDevice: () => null,
  sendMFACode: () => null,
  listDevices: () => null,
  sendMFASelectionAnswer: () => null,
  completeNewPasswordChallenge: () => null,
  signOut: () => null,
  globalSignOut: () => null,
  verifyAttribute: () => null,
  getUserAttributes: () => null,
  setUserMfaPreference: () => null,
  updateAttributes: () => null,
  deleteAttributes: () => null,
  getAttributeVerificationCode: () => null,
  deleteUser: () => null,
  enableMFA: () => null,
  disableMFA: () => null,
  getMFAOptions: () => null,
  getUserData: () => null,
  associateSoftwareToken: () => null,
  verifySoftwareToken: () => null,
};

const dummyContext: UseAuth = {
  isLoading: false,
  isAuthenticated: false,
  user: null,
  signIn: async () => dummyUser,
  signOut: async () => ({ success: false, message: '' }),
  sendResetPasswordEmail: async () => false,
  resetPasswordSubmit: async () => {},
  completeNewPassword: async () => dummyUser,
};

const authContext = createContext(dummyContext);

export const ProvideAuth: React.FC<Props> = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export function useAuth(): UseAuth {
  return useContext(authContext);
}

function useProvideAuth(): UseAuth {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<CognitoUserExtended | null>(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((result: CognitoUserExtended) => {
        setUser(result);
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch(() => {
        setUser(null);
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, []);

  async function signIn(
    username: string,
    password: string
  ): Promise<CognitoUserExtended> {
    try {
      const result = (await Auth.signIn(
        username,
        password
      )) as CognitoUserExtended;
      setUser(result);
      setIsAuthenticated(true);
      return result;
    } catch (error) {
      console.log('error signing in', error);
      throw error;
    }
  }

  async function signOut(): Promise<{ success: boolean; message: string }> {
    try {
      await Auth.signOut();
      setUser(null);
      setIsAuthenticated(false);
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'LOGOUT FAIL',
      };
    }
  }

  async function sendResetPasswordEmail(email: string): Promise<boolean> {
    try {
      await Auth.forgotPassword(email);
      return true;
    } catch (error) {
      return false;
    }
  }

  async function resetPasswordSubmit(
    email: string,
    code: string,
    newPassword: string
  ): Promise<void> {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  }

  async function completeNewPassword(
    newPassword: string
  ): Promise<CognitoUserExtended> {
    if (user === null) {
      throw new Error('No user');
    }

    try {
      const result = (await Auth.completeNewPassword(
        user,
        newPassword
      )) as CognitoUserExtended;
      setUser(result);
      setIsAuthenticated(true);
      return result;
    } catch (error) {
      console.log('error signing in', error);
      throw error;
    }
  }

  return {
    isLoading,
    isAuthenticated,
    user,
    signIn,
    signOut,
    sendResetPasswordEmail,
    resetPasswordSubmit,
    completeNewPassword,
  };
}
