export default function AuthContainer({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div className="md:w-1/2">
      <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
        <div className="flex-1"></div>
        <div className="max-w-sm mx-auto w-full px-4 py-8">{children}</div>
      </div>
    </div>
  );
}
