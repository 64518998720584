"use client"
import { Area, CartesianGrid, XAxis, ComposedChart,  Line, YAxis } from "recharts"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart"

import type { Stack } from '@/types/Stack';
import { useData } from "@/hooks/useData"
import { LoadingSpinner } from "@/components/ui/LoadingSpinner";
export const description = "A step area chart"

var emptyData = {
  "locationIds": [],
  "bucketData": [
    {
      "bucket": "1h",
      "data": [
    ]
    },
    {
      "bucket": "15min",
      "data": []
    },
    {
      "bucket": "5min",
      "data": []
    },
    {
      "bucket": "30min",
      "data": [],
    },
    {
      "bucket": "raw",
      "data": []
    },
    {
        "bucket": "24h",
        "data": []
      },
  ],
  "driveOffData": []
} as Stack;

const chartConfig = {
  max: {
    label: "Max",
    color: "hsl(var(--chart-1))",
  },
  min: {
    label: "min",
    color: "hsl(var(--chart-2))",
  },
  mean: {
    label: "mean",
    color: "hsl(var(--chart-3))",
  },
  driveOffs: {
    label: "driveOffs",
    color: "hsl(var(--chart-5))",
  },
} satisfies ChartConfig

export default function StackComponent({
  stackData,
}: {
  stackData: Stack | undefined;
}) {
  const { selectedDates, selectedStackDriveOffFilter, selectedLocations } = useData();
  if (stackData?.bucketData === undefined){
    stackData = emptyData as Stack;
  }
  stackData = stackData;
  const options: Intl.DateTimeFormatOptions = selectedStackDriveOffFilter === '24h'
  ? { dateStyle: 'short' }  // Use dateStyle if "24h"
  : { timeStyle: 'short' }; // Use timeStyle otherwise

  function formatter(localDate: string): string{
    const formattedDate = Intl.DateTimeFormat('en-US', options ).format(new Date(localDate))
  
    return formattedDate
  
  }
  
  //logic to allow graph to stay populated while changing dates
  emptyData = stackData;

  return (
    <Card className="text-slate-800 dark:text-slate-100 bg-white dark:bg-slate-800 shadow-lg rounded-lg border border-gray-100 dark:border-slate-700 max-w-[95%]" >
      <CardHeader>
        <CardTitle>Stack / DriveOff Chart for <b>{selectedLocations[0]?.displayName}</b></CardTitle>
        <CardDescription>
          Car Stack Data for {selectedDates[0].toDateString()} to {selectedDates[selectedDates.length - 1].toDateString()}
        </CardDescription>
      </CardHeader>
      <CardContent >

      {stackData.bucketData[stackData.bucketData.findIndex(item => item.bucket === 'raw')].data.length === 0 && (
          <LoadingSpinner></LoadingSpinner>
      )}
      {stackData.bucketData[stackData.bucketData.findIndex(item => item.bucket === 'raw')].data.length !== 0 && (
        <ChartContainer config={chartConfig}>
          
          <ComposedChart
            accessibilityLayer
            data={stackData.bucketData[stackData.bucketData.findIndex(item => item.bucket === selectedStackDriveOffFilter)].data}
            margin={{
              bottom: 20
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="TimeStamp"
              tickLine={false}
              axisLine={false}
              angle={-35}
              textAnchor={"end"}
              fontSize={14}
              tickMargin={6}
              tickFormatter={(value) => formatter(value)}
              
            />
            <YAxis
                tickLine={false}
                axisLine={false}
            />
            <ChartTooltip 
              cursor={false}
              content={<ChartTooltipContent className="bg-white dark:bg-slate-800"/>}>
              </ChartTooltip>
            
            <Area
              dataKey="StackSize_max"
              type="step"
              fill="var(--color-max)"
              fillOpacity={1}
              stroke="var(--color-max)"
              dot={false}
            />
            <Area
              dataKey="StackSize_mean"
              type="step"
              fill="var(--color-mean)"
              fillOpacity={1}
              stroke="var(--color-mean)"
            />
            <Area
              dataKey="StackSize_min"
              type="step"
              fill="var(--color-min)"
              fillOpacity={1}
              stroke="var(--color-min)"
              dot={false}
            />
            <Area
              dataKey="StackSize"
              fill="var(--color-mean)"
              fillOpacity={0.4}
              stroke="var(--color-mean)"
            />
          
            <Line 
              dataKey="DriveOffs" 
              strokeWidth = {0} 
              type="natural" 
              fill="var(--color-driveOffs)" 
              stroke="var(--color-driveOffs)" 
              dot={{fill: 
                "var(--color-driveOffs)", r:10
              }}
              activeDot={{
                r: 15, fill: 
                "var(--color-driveOffs)"
              }}
            />
          </ComposedChart>
        </ChartContainer>
      )}
      </CardContent>
    </Card>

  )
}